import 'promise/polyfill';

import React from 'react';
import ReactDOM from 'react-dom';
import debug from 'debug';
import './styles/main.less';
import './index.css';
import { logger, getLocalStorage } from './utils';

import Iam from './Iam';
import './Iam.less';

// debug.enable('iam:*,-sockjs-client:*');
debug.enable('*');

ReactDOM.render(
  <Iam />,
  document.getElementById('root')
);

window.addEventListener('unload', function(event) {
  const ls = getLocalStorage();
  logger('iam: ', 'about to clear localStorage for iam-ui.');
  // clear oidc keys
  const oidcKeys = Object.keys(localStorage).filter(item => item.indexOf('oidc-') > -1) || [];
  oidcKeys.forEach(key => ls.removeItem(key));
  ls.removeItem('id_tokenauth_response');

  // window.localStorage.clear();
});
