// import React from 'react';
// import { Paragraph } from 'prism-reactjs';

export const adProvidersListColumns =
[
  {
    title: 'Name',
    key: 'name'
  },
  {
    title: 'Domain',
    key: 'domain_name'
  }, {
    title: 'URL',
    key: 'url'
  }
];

export const columnsToSpecs = {
  uuid: 'metadata.uuid',
  name: 'spec.name',
  domain_name: 'spec.resources.domain_name',
  url: 'spec.resources.url'
};

export const adProvidersListActions = {
  primary: [
    {
      key: 'add-provider',
      value: 'Add Directory Provider'
    },
    {
      key: 'delete',
      value: 'Delete',
      selectionCount: -1
    }
  ],
  secondary: [
    {
      key: 'update-provider',
      value: 'Update',
      selectionCount: 1
    }
  ]

};

export const adProvidersListRowAction = {
  actions: [{
    key: 'delete',
    value: 'Delete'
  }],
  getPopupContainer: () => document.querySelector('.body-wrapper')
};

export const adProvidersListPagination = {
  total: 0,
  pageSize: 5,
  currentPage: 1,
  pageSizeOptions: [5, 10, 15, 25, 50]
};
