import * as React from 'react';
import PropTypes from 'prop-types';
import { ContainerLayout, Table } from 'prism-reactjs';

import { serviceAccountsListColumns, serviceAccountsListPagination,
  serviceAccountsListActions, manageserviceAccountKeysLocalActions } from '../../metadata';
import { logger, bindFunctions, getModelByKey } from '../../utils';
import { Actions } from '../../components/Common';
import ManageServiceAccountKeys from './ManageServiceAccountKeys.jsx';
import CreateServiceAccount from './CreateServiceAccount.jsx';

class ServiceAccountsList extends React.Component {

  static propTypes = {
    actions: PropTypes.object,
    columns: PropTypes.array,
    api: PropTypes.object,
    topSection: PropTypes.object,
    apiFetchRequest: PropTypes.func,
    clearCacheForEndpoint: PropTypes.func
  }

  static defaultProps = {
    actions: Object.assign({}, serviceAccountsListActions),
    columns: serviceAccountsListColumns,
    users: {},
    topSection: {
      title: 'Viewing {1} all service accounts'
    }
  }

  constructor(props) {
    super(props);
    this.state = {
      pagination: Object.assign({}, serviceAccountsListPagination),
      rowSelection: {
        selected: []
      },
      showManageKeysModal: false
    };
    bindFunctions(['handleChangePagination', 'handleAction',
      'handleChangeRowSelection', 'clearApiCache', 'onHandleAccountKeysModalClick',
      'onHandleCreateAccountModalClick'], this);
  }

  componentDidMount() {
    // check and route to login page or providers page.
    logger('iam:ServiceAccountsList', 'check and route to login page or providers page.');
    const { pagination } = this.state;
    this.props.apiFetchRequest({ endpoint: 'GET_SERVICE_ACCOUNTS',
      body: {
        length: pagination.pageSize,
        offset: (pagination.currentPage - 1) * pagination.pageSize
      }
    });
  }
  componentWillUnmount() {
    this.clearApiCache();
  }

  clearApiCache() {
    this.props.clearCacheForEndpoint([{ endpoint: 'GET_SERVICE_ACCOUNTS' },
      { endpoint: 'DELETE_SERVICE_ACCOUNT_KEY' },
      { endpoint: 'CREATE_SERVICE_ACCOUNTS_KEY' }
    ]);
  }

  render() {
    const { columns, api, topSection } = this.props;
    const { pagination, showManageKeysModal, showCreateServiceAccountModal } = this.state;
    const { GET_SERVICE_ACCOUNTS } = api;
    const data = (GET_SERVICE_ACCOUNTS && GET_SERVICE_ACCOUNTS.result)
      ? GET_SERVICE_ACCOUNTS.result : [];
    const totalCount = (GET_SERVICE_ACCOUNTS) ? GET_SERVICE_ACCOUNTS.total_matches : 0;
    const title = topSection.title.replace('{1}', totalCount);
    // added hack to fix count for now.
    const _paginaion = Object.assign({}, pagination);
    _paginaion.total = totalCount || data.length;

    return (
      <ContainerLayout className="UserList" padding="10px">
        { this.getActions() }
        <Table rowKey="uuid" oldTable={ false } dataSource={ data } columns={ columns }
          topSection={ { title } }
          pagination={ _paginaion }
          onChangePagination={ this.handleChangePagination }
          onChangeRowSelection={ this.handleChangeRowSelection }
          rowAction={ this.rowAction }
          rowSelection={ this.state.rowSelection }
          structure={ {
            paginationPosition: {
              top: true,
              bottom: true
            }
          } } />
        {
          (showManageKeysModal) && (
            <ManageServiceAccountKeys api={ api } account={ this.getSelectedModel() }
              handleModalClick={ this.onHandleAccountKeysModalClick } />
          )
        }
        {
          (showCreateServiceAccountModal) && (
            <CreateServiceAccount handleModalClick={ this.onHandleCreateAccountModalClick } />
          )
        }
      </ContainerLayout>
    );
  }

  getActions() {
    const onHandleAction = this.handleAction;
    return (<Actions cssClass="SamlProvidersList_Actions"
      handleAction={ onHandleAction }
      selectionCount={ this.state.rowSelection.selected.length }
      actions={ this.props.actions } />);
  }

  getSelectedModel(uuid) {
    if (this.state.rowSelection.selected && this.state.rowSelection.selected.length === 1) {
      return this.getRowModel(uuid = this.state.rowSelection.selected[0]);
    }
  }

  getRowModel(uuid) {
    if (!uuid && this.state.rowSelection.selected && this.state.rowSelection.selected.length > 0) {
      uuid = this.state.rowSelection.selected[0];
    }
    const { GET_SERVICE_ACCOUNTS } = this.props.api;
    if (GET_SERVICE_ACCOUNTS && GET_SERVICE_ACCOUNTS.result) {
      return getModelByKey('uuid', uuid, GET_SERVICE_ACCOUNTS.result);
    }
  }

  handleAction(event) {
    const action = event.currentTarget.dataset.action;
    logger('ServiceAccountsList.handleAction', action);
    if (action === 'manage-certificates') {
      this.setState(Object.assign(this.state,
        { showManageKeysModal: !this.state.showManageKeysModal }));
    } else if (action === 'create') {
      this.setState(Object.assign(this.state,
        { showCreateServiceAccountModal: !this.state.showCreateServiceAccountModal }));
    } else if (action === 'delete') {
      const selected = this.state.rowSelection.selected || [];
      const requests = [];
      selected.forEach((uuid) => {
        requests.push({
          endpoint: 'DELETE_SERVICE_ACCOUNT',
          body: {
            uuid
          }
        });
      });
      requests.push({ endpoint: 'GET_SERVICE_ACCOUNTS' });
      this.props.apiFetchRequest(requests);
      this.setState(...this.state, {
        rowSelection: {
          selected: []
        }
      });
    }
  }

  handleChangeRowSelection(rowSelection) {
    this.setState({
      rowSelection
    });
  }

  handleChangePagination(pagination) {
    logger('ServiceAccountsList:onHandleChangePagination', JSON.stringify(pagination));
    // set the state with new pagination.
    this.setState(Object.assign({}, this.state, { pagination }));
    this.props.apiFetchRequest(
      {
        endpoint: 'GET_SERVICE_ACCOUNTS',
        body: {
          length: pagination.pageSize,
          offset: (pagination.currentPage - 1) * pagination.pageSize
        }
      }
    );
  }

  onHandleAccountKeysModalClick(action, body) {
    if (typeof action !== 'string') {
      this.setState(Object.assign(this.state,
        { showManageKeysModal: !this.state.showManageKeysModal }));
      // when we close the modal we clear CREATE_SERVICE_ACCOUNTS_KEY from cache.
      this.props.clearCacheForEndpoint([{ endpoint: 'CREATE_SERVICE_ACCOUNTS_KEY' },
        { endpoint: 'DELETE_SERVICE_ACCOUNT_KEY' }]);
      this.props.apiFetchRequest({ endpoint: 'GET_SERVICE_ACCOUNTS' });
    } else if (action === manageserviceAccountKeysLocalActions.CREATE_KEY) {
      // @TODO will add name and desc after apis have it.
      logger('ServiceAccountsList:onHandleAccountKeysModalClick', action, JSON.stringify(body));
      this.props.clearCacheForEndpoint({ endpoint: 'CREATE_SERVICE_ACCOUNTS_KEY' });
      this.props.apiFetchRequest({
        passthrough: false,
        endpoint: 'CREATE_SERVICE_ACCOUNTS_KEY',
        body: { uuid: body.uuid }
      });
      // this.props.apiFetchRequest({ endpoint: 'GET_SERVICE_ACCOUNTS' });
    } else if (action === manageserviceAccountKeysLocalActions.REVOKE_KEY) {
      this.props.clearCacheForEndpoint({ endpoint: 'DELETE_SERVICE_ACCOUNT_KEY' });
      this.props.apiFetchRequest([{
        endpoint: 'DELETE_SERVICE_ACCOUNT_KEY',
        body
      },
      { endpoint: 'GET_SERVICE_ACCOUNTS' }
      ]);
    }
  }

  onHandleCreateAccountModalClick(action, payload) {
    if (typeof action !== 'string' || action === 'close') {
      this.setState(Object.assign(this.state,
        { showCreateServiceAccountModal: !this.state.showCreateServiceAccountModal }));
      // this.props.apiFetchRequest({ endpoint: 'GET_SERVICE_ACCOUNTS' });
    }
  }

}

export default ServiceAccountsList;
