import { Log, UserManager } from 'oidc-client';

import { oidc } from '../config';
import { logger } from '../utils';

class AuthService {

  userManager = null;
  // /${oidc.clientRoot}
  constructor() {
    const settings = {
      authority: oidc.stsAuthority,
      client_id: oidc.clientId,
      post_logout_redirect_uri: `${oidc.clientRoot}`,
      redirect_uri: `${window.location.protocol}/${window.location.host}/callback`,
      response_type: 'id_token token',
      scope: oidc.clientScope,
      silent_redirect_uri: `${oidc.clientRoot}silent-renew`
    };
    this.userManager = new UserManager(settings);
    this.userManager.events.addUserLoaded(() => {
      logger('iam.UserManager', ' Login Successful');
    });

    Log.logger = console;
    Log.level = Log.INFO;
  }

  getUser() {
    return this.userManager.getUser();
  }

  login() {
    return this.userManager.signinRedirect();
  }

  renewToken() {
    return this.userManager.signinSilent();
  }

  logout() {
    return this.userManager.signoutRedirect();
  }

}

let AuthServiceInstance = null;
// Auth service need to be static.
export const getAuthService = () => {
  if (!AuthServiceInstance) {
    AuthServiceInstance = new AuthService();
  }
  return AuthServiceInstance;
};
