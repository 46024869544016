import * as constants from '../constants';

const jwtJsDecode = require('jwt-js-decode');

/**
 * Document it
 * @param {object} state - state
 * @param {object} action - action
 * @returns {object} - mutated state
 */
export default function oauth(state, action) {
  switch (action.type) {
    case constants.OAUTH_TOKEN_SUCCESS:
      if (action.jwt && action.jwt.id_token) {
        action.jwt.decoded_jwt = jwtJsDecode.jwtDecode(action.jwt.id_token);
      }
      return { ...state,
        ...action.jwt
      };
    default:
      return state || null;
  }
}
