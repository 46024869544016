/**
 * General Metheod to save value to sessionStorage
 * @param {String} key - key for sessionStorage
 * @param {String} value  - key to store
 */
export const setSessionValue = (key, value) => {
  sessionStorage.setItem(key, value);
};

/**
 * General Metheod to get value from sessionStorage
 * @param {String} key - key for sessionStorage
 * @return {String} - return value from sessionStorage
 */
export const getSessionValue = (key) => {
  return sessionStorage.getItem(key);
};

/**
 * General Metheod to remove item from sessionStorage
 * @param {String} key - key for sessionStorage
 * @return {String} - return value from sessionStorage
 */
export const removeSessionValue = (key) => {
  return sessionStorage.removeItem(key);
};
