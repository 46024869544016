import React from 'react';
import { Paragraph } from 'prism-reactjs';

export const samlProvidersListColumns =
[
  {
    title: 'Name',
    key: 'saml_idp_name'
  },
  {
    title: 'Login URL',
    key: 'idp_properties',
    render: (val) => {
      return (
        <Paragraph>
          {val.login_url}
        </Paragraph>
      );
    }
  },
  {
    title: 'Username Attribute',
    key: 'username_attr'
  },
  {
    title: 'Email Attribute',
    key: 'email_attr'
  }
];

export const samlProvidersListActions = {
  primary: [
    {
      key: 'add-provider',
      value: 'Add SAML Provider'
    },
    {
      key: 'delete',
      value: 'Delete',
      selectionCount: -1
    }
  ],
  DISABLEDsecondary: [
    {
      key: 'update-provider',
      value: 'Update',
      selectionCount: 1
    }
  ]

};

export const samlProvidersListRowAction = {
  actions: [{
    key: 'delete',
    value: 'Delete'
  }],
  getPopupContainer: () => document.querySelector('.body-wrapper')
};

export const samlProvidersListPagination = {
  total: 0,
  pageSize: 5,
  currentPage: 1,
  pageSizeOptions: [5, 10, 15, 25, 50]
};
