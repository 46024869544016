import * as React from 'react';
// import debug from 'debug';

import { Button, ContainerLayout, FlexLayout,
  Title } from 'prism-reactjs';
import { getAuthService } from '../../api/auth-service';
import { logger } from '../../utils';

class ForceLogin extends React.Component {

  static propTypes = {
  }

  constructor(props) {
    super(props);
    this.authService = getAuthService();
  }

  componentDidMount() {
    // check and route to login page or providers page.
    logger('iam:ActionsButtons', 'check and route to login page or providers page.');
    // this.getUser();
  }

  componentWillUnmount() {
    this.authService = null;
  }

  render() {
    return (
      <ContainerLayout>
        { this.getBodyContent() }
      </ContainerLayout>
    );
  }

  getBodyContent(users) {
    // Upgrade ParticleBackground
    return (
      <div>
        <FlexLayout
          className="LoginPage"
          justifyContent="center"
        >
          <FlexLayout
            className="loginpage-form"
            alignItems="center"
            flexDirection="column"
            itemSpacing="40px"
            justifyContent="center"
          >
            <Title className="dark-bg align-center">
              { 'Please login to continue...' }
            </Title>
            <Button
              type="dark-primary"
              fullWidth={ true }
              onClick={ this.handleLogin }
            >
              Login
            </Button>
            <Title className="dark-bg align-center" size="h4">
              { 'Nutanix Inc.' }
            </Title>
          </FlexLayout>
        </FlexLayout>
      </div>
    );
  }

  handleLogin = () => {
    this.authService.login();
  };

}

export default ForceLogin;
