import * as React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

import { ContainerLayout, Table, StackingLayout }
  from 'prism-reactjs';

import { apiFetchRequest } from '../../actions';
import { Actions } from '../../components/Common';

import { adProvidersListColumns, adProvidersListPagination,
  adProvidersListRowAction, adProvidersListActions, columnsToSpecs } from '../../metadata';
import { bindFunctions, logger, getDescendantProp } from '../../utils';
import AddDirectoryProvider from './AddDirectoryProvider.jsx';


class AdProvidersList extends React.Component {

  static propTypes = {
    actions: PropTypes.object,
    api: PropTypes.object,
    apiFetchRequest: PropTypes.func,
    columns: PropTypes.array,
    adidplist: PropTypes.object,
    pagination: PropTypes.object,
    topSection: PropTypes.object
  }

  static defaultProps = {
    actions: Object.assign({}, adProvidersListActions),
    columns: adProvidersListColumns,
    pagination: Object.assign({}, adProvidersListPagination),
    adidplist: {},
    topSection: {
      title: 'Directory Providers'
    }
  }

  constructor(props) {
    super(props);
    this.state = {
      pagination: Object.assign({}, props.pagination),
      rowSelection: {
        selected: []
      },
      showAddProviderModal: false
    };

    bindFunctions(['handleChangeRowSelection', 'handleRowAction', 'onHandleAction',
      'handleDeleteProvider', 'onHandleCreateProviderModalClick', 'handleChangePagination'], this);

    this.rowAction = Object.assign({}, adProvidersListRowAction,
      { onRowAction: this.handleRowAction });
  }

  componentDidMount() {
    // check and route to login page or providers page.
    logger('iam:ProvidersMain', 'check and route to login page or providers page.');
    this.props.apiFetchRequest({ endpoint: 'GET_DIRECTORY_SERVICES' });
  }
  render() {
    const { columns, adidplist = {}, topSection, pagination, api } = this.props;
    const { showAddProviderModal, providerModalMode, idpId } = this.state;
    const data = adidplist.result || [];
    // <Button disabled={ this.state.rowSelection.selected.length !== 1 }>Edit</Button>
    // @todo hack to fix pa
    const _pagination = Object.assign({}, pagination);
    _pagination.total = data.length;
    if (data.length === 0) {
      _pagination.currentPage = 1;
    }
    return (
      <ContainerLayout className="SamlProviders--List" padding="10px">
        <StackingLayout>
          { this.getActions() }
          <Table
            rowKey="uuid"
            oldTable={ false }
            dataSource={ data }
            columns={ columns }
            onChangeRowSelection={ this.handleChangeRowSelection }
            onChangePagination={ this.handleChangePagination }
            pagination={ _pagination }
            rowAction={ this.rowAction }
            rowSelection={ this.state.rowSelection }
            structure={
              {
                paginationPosition: {
                  top: true,
                  bottom: true
                }
              }
            }
            topSection={ topSection } />
        </StackingLayout>
        {
          (showAddProviderModal) &&
            <AddDirectoryProvider visible={ showAddProviderModal }
              api={ api }
              mode={ providerModalMode }
              idpId={ idpId }
              apiFetchRequest={ this.props.apiFetchRequest }
              handleModalClick={ this.onHandleCreateProviderModalClick } />
        }
      </ContainerLayout>
    );
  }

  getActions() {
    return (<Actions cssClass="ServiceAccountList_Actions"
      handleAction={ this.onHandleAction }
      selectionCount={ this.state.rowSelection.selected.length }
      actions={ this.props.actions } />);
  }

  handleRowAction(key, rowData) {
    // logger(`${key} : ${JSON.stringify(rowData)}`);
    this.onHandleAction(key, rowData.uuid);
  }
  handleChangeRowSelection(rowSelection) {
    this.setState({
      rowSelection
    });
  }

  handleDeleteProvider(event) {
    // const param = 'idp_id';

    logger('SamlProvider:handleDeleteProvider', 'check and route to login page or providers page.');
    logger(JSON.stringify(this.state.rowSelection));
    if (this.state.rowSelection && this.state.rowSelection.selected &&
      this.state.rowSelection.selected.length > 0) {
      this.onHandleAction('DELETE', this.state.rowSelection.selected[0]);
    }
  }

  onHandleAction(event) {
    const action = event.currentTarget.dataset.action;
    logger('iam:SamlProvidersList', `execute action ${action}`);
    if (action === 'delete') {
      if (this.state.rowSelection && this.state.rowSelection.selected &&
        this.state.rowSelection.selected.length > 0) {
        this.props.apiFetchRequest([
          {
            endpoint: 'DELETE_SAML_IDP',
            body: {
              idp_id: this.state.rowSelection.selected[0]
            }
          },
          { endpoint: 'GET_DIRECTORY_SERVICES' }]);
      }
    } else if (action === 'add-provider') {
      this.setState(...this.state, { showAddProviderModal: true });
    } else if (action === 'update-provider') {
      this.setState(...this.state, { showAddProviderModal: true,
        providerModalMode: 'update',
        idpId: this.state.rowSelection.selected[0] });
    }

    this.props.apiFetchRequest({ endpoint: 'GET_DIRECTORY_SERVICES' });
  }


  onHandleCreateProviderModalClick(action, payload) {
    if (typeof action !== 'string' || action === 'close') {
      this.setState(...this.state, { showAddProviderModal: false });
      this.props.apiFetchRequest({ endpoint: 'GET_DIRECTORY_SERVICES' });
    } else if (action === 'add-provider') {
      this.setState(...this.state, { showAddProviderModal: false });
    }
  }

  normalizeData(list) {
    let anItem;
    const allItems = [];
    const keys = Object.keys(columnsToSpecs);
    list.forEach((element, index) => {
      anItem = {};
      keys.forEach((key) => {
        anItem[key] = getDescendantProp(element, columnsToSpecs[key]);
      });
      allItems.push(anItem);
    });

    return allItems;
  }

  handleChangePagination(pagination) {
    logger('iam:SamlProvidersList:onHandleChangePagination', JSON.stringify(pagination));
    // set the state with new pagination.
    this.setState(Object.assign({}, this.state, { pagination }));
    this.props.apiFetchRequest(
      {
        endpoint: 'GET_DIRECTORY_SERVICES',
        body: {
          length: pagination.pageSize,
          offset: (pagination.currentPage - 1) * pagination.pageSize
        }
      }
    );
  }

}

const mapStateToProps = (state) => {
  return {
    api: state.api,
    adidplist: state.api.GET_DIRECTORY_SERVICES,
    deleteDirectoryResponse: state.api.DELETE_DIRECTORY_SERVICES
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ apiFetchRequest }, dispatch);
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AdProvidersList));
