import { takeLatest } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { apiFetchSuccess, apiFetchRequest, apiFetchFailed,
  prismApiFetchSuccess, prismApiFetchFailed,
  prismApiFetchCustomLoginSuccess } from '../actions';
import { GET_SYSTEM_CONFIG, GET_PRISM_VERSION, GET_PRISM_LOGIN_CUSTOM } from '../constants';
import { logger } from './../utils';
import { fetchSystemConfig, fetchPrismVersion } from '../api/system-config-api';

/**
 *  getSystemComfig watcher
 */
export function* watchGetSystemConfig() {
  yield takeLatest(GET_SYSTEM_CONFIG, fetchSystemConfigRequest);
}

/**
 *  getPrismVersion watcher
 */
export function* watchGetPrismVersion() {
  yield takeLatest(GET_PRISM_VERSION, fetchPrismVersionRequest);
  // yield takeLatest(GET_PRISM_LOGIN_CUSTOM, fetchPrismVersionRequest);
}

/**
 * fetch system configuration saga
 * handles fetch for system configuration and dispatches display of welcome banner
 * if response has welcome_banner_status = true or dispatches getConnectors actions
 * if welcome_banner_status = false
 * @param {Object} payload - payload for the worker
 */
function* fetchSystemConfigRequest(payload) {
  try {
    const response = yield call(fetchSystemConfig, payload.request);
    if (response.welcome_banner_status) {
      yield put(apiFetchSuccess({ endpoint: payload.type,
        json: response }));
    }
    // Make sure GET_CONNECTORS always called after get welcome content
    yield put(apiFetchRequest({ endpoint: 'GET_CONNECTORS' }));
  } catch (error) {
    logger('systemConfigSaga', error);
    yield put(apiFetchFailed({ endpoint: payload.type,
      json: error }));
  }
}

const DEFAULT_VERSION = 'euphrates-5.19-stable-pc-0';

/**
 * fetch prism prelogin details saga
 * handles fetch for prism prelogin details and get software version
 * dispatches display of help link
 * @param {Object} payload - payload for the worker
 */
function* fetchPrismVersionRequest(payload) {
  try {
    const { name } = payload.request;
    const response = yield call(fetchPrismVersion, payload.request);
    if (response[name]) {
      const { version } = response[name];
      yield put(prismApiFetchSuccess({
        endpoint: payload.type,
        json: version
      }));
    }
    // If PrismAPI fetch success, get other action dispatch
    if (response && response.customLogin) {
      yield put(prismApiFetchCustomLoginSuccess({
        endpoint: GET_PRISM_LOGIN_CUSTOM,
        json: response.customLogin
      }));
    }
  } catch (error) {
    logger('systemConfigSaga', error);
    yield put(prismApiFetchFailed({
      endpoint: payload.type,
      json: DEFAULT_VERSION
    }));
  }
}
