export const policyFormat = [
  {
    label: 'Persistant',
    value: 'Persistant'
  },
  {
    label: 'Email Address',
    value: 'emailAddress'
  },
  {
    label: 'Unspecified',
    value: 'unspecified'
  },
  {
    label: 'X509SubjectName',
    value: 'X509SubjectName'
  },
  {
    label: 'WindowsDomainQualifiedName',
    value: 'WindowsDomainQualifiedName'
  },
  {
    label: 'Encrypted',
    value: 'encrypted'
  },
  {
    label: 'Entity',
    value: 'entity'
  },
  {
    label: 'Kerberos',
    value: 'kerberos'
  },
  {
    label: 'Transient',
    value: 'transient'
  }

];
