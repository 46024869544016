// import { takeLatest } from 'redux-saga';
import { all, fork, takeEvery, throttle } from 'redux-saga/effects';
import { fetchAPI, clearAPICache } from './api-saga';
import { watchGetSystemConfig, watchGetPrismVersion } from './system-config-saga';

import * as constants from '../constants';

const apiThrottleTime = 1000;

/**
 * add here all your watchers
 * https://github.com/yelouafi/redux-saga/blob/master/examples/real-world/sagas/index.js
 */
function* watchApiRequest() {
  yield takeEvery(constants.API_FETCH_REQUEST, fetchAPI);
}

/**
 * For api requests that need to be throttled.
 */
function* watchApiRequestThrottled() {
  yield throttle(apiThrottleTime, constants.API_FETCH_REQUEST_THROTTLED, fetchAPI);
}

/**
 * Watch the api cleanup request.
 */
function* watchClearCache() {
  yield takeEvery(constants.API_CLEAR, clearAPICache);
}
/**
 * Register all your watchers
 */
export default function* root() {
  yield all([
    fork(watchApiRequest),
    fork(watchApiRequestThrottled),
    fork(watchClearCache),
    fork(watchGetSystemConfig),
    fork(watchGetPrismVersion)
  ]);
}
