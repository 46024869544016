import * as constants from '../constants';

/**
 * Document it
 * @param {string} jwt - jwt token issued
 * @returns {object} - jwt token and action
 */
export function oauthTokenSuccess(jwt) {
  return {
    jwt,
    type: constants.OAUTH_TOKEN_SUCCESS
  };
}

export const initiateAuth = () => {
  return {
    type: constants.OAUTH_INITIATE
  };
};
