import * as React from 'react';
import PropTypes from 'prop-types';
import { Enum } from 'enumify';

import { Button,
  FormItemProvider,
  ContainerLayout,
  FlexLayout,
  Radio,
  RadioGroup,
  Select,
  Modal,
  Input,
  FormItemInput,
  StackingLayout
} from 'prism-reactjs';

import { logger, bindFunctions } from '../../utils';
import { Upload } from '../../components/Common';

import { policyFormat } from '../../metadata/settings/AddProvider.jsx';

class Type extends Enum {}

Type.initEnum(['url', 'metadata', 'details']);

class AddProvider extends React.Component {

  static propTypes = {
    api: PropTypes.object,
    apiFetchRequest: PropTypes.func,
    clearCacheForEndpoint: PropTypes.func,
    samlModel: PropTypes.object,
    requiredFields: PropTypes.object,
    handleModalClick: PropTypes.func,
    visible: PropTypes.bool,
    error: PropTypes.object,
    idpId: PropTypes.string,
    mode: PropTypes.oneOf(['add', 'update'])
  }

  static defaultProps = {
    requiredFields: {
      saml_idp_name: 'Idp Name is required',
      username_attr: 'Username attribure is required',
      email_attr: 'Email attribute is required',
      'idp_properties.login_url': 'Login url is required',
      'idp_properties.certificate': 'Cert for verificationrtion is required'
    }
  }


  constructor(props) {
    super(props);
    bindFunctions(['handleChange', 'handleOnClick', 'handleTypeRadioOnChange',
      'handleOnMetadataUpload', 'onHandleBeforeUpload'], this);

    this.hydrateState();
  }

  hydrateState(props) {
    const { api = {} } = this.props;
    const provider = api.GET_SAML_IDP;
    if (this.props.mode === 'update') {
      logger('UpdateProvider', JSON.stringify(provider));
    }
    this.state = {
      idp_metadata_url: 'https://nutanixdev.oktapreview.com/app/exkifyrefzmYu2Nl60h7/sso/saml/metadata',
      idpName: '',
      usernameAttr: '',
      emailAttr: '',
      custom_attr: [
        'phone', 'custom1'
      ],
      groups_attr: '',
      idp_metadata: '',
      idp_properties: {
        idp_url: '',
        login_url: '',
        logout_url: '',
        error_url: '',
        redirect_url: '',
        certificate: '',
        name_id_policy_format: 'persistent'
      },
      type: 'url',
      validation: [],
      uploadPlaceholder: 'No file chosen'
    };
  }
  componentDidMount() {
    logger('AddProvider', 'check and route to login page or providers page.');
    const { mode, idpId } = this.props;
    if (mode === 'update' && idpId) {
      this.props.apiFetchRequest(
        {
          endpoint: 'GET_SAML_IDP',
          body: {
            idp_id: idpId
          }
        }
      );
    }

    this.hydrateState();
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.error) {
      this.hydrateState();
    }
  }
  componentWillUnmount() {
    this.props.clearCacheForEndpoint({ endpoint: 'ADD_SAML_IDP' });
  }

  render() {
    const { samlModel, visible, handleModalClick } = this.props;

    return (
      <Modal
        visible={ visible }
        title="Configure a New Provider"
        contentClassName="iam-modal-content"
        onCancel={ handleModalClick }
        footer={ this.getFooter() }
        width={ 750 }
      >
        <ContainerLayout padding="15px">
          { this.getBodyContent(samlModel) }
        </ContainerLayout>
      </Modal>
    );
  }

  getBodyContent() {
    const { email_attr, saml_idp_name, username_attr, groups_attr } = this.state;

    return (
      <FlexLayout itemSpacing="10px" itemFlexBasis="100pc" >
        <StackingLayout >
          { alert }
          <FormItemInput id="saml_idp_name" name="saml_idp_name"
            label="Name of the Identity Provider Configuration"
            onChange={ this.handleChange } value={ saml_idp_name }
            subLabel="(required)"
            placeholder="IDP friendly name" />
          <FormItemInput id="username_attr" name="username_attr" label="User Attribute"
            subLabel="(required)"
            onChange={ this.handleChange } value={ username_attr } placeholder="name" />
          <FormItemInput id="email_attr" name="email_attr" label="Email Attribute"
            subLabel="(required)"
            onChange={ this.handleChange } value={ email_attr } placeholder="email" />
          <FormItemInput id="groups_attr" name="groups_attr" label="Groups Attribute"
            onChange={ this.handleChange } value={ groups_attr } placeholder="groups" />
          <RadioGroup defaultValue="url" name="type" id="typeRadioGroup"
            onChange={ this.handleTypeRadioOnChange }>
            <Radio name="type" title="URL" value="url" />
            <Radio name="type" title="Metadata" value="metadata" />
            <Radio name="type" title="Enter Configuration Manually" value="details" />
          </RadioGroup>
          {
            this.getFormDetails()
          }
        </StackingLayout>
      </FlexLayout>
    );
  }

  getFormDetails() {
    const style = {
      width: '600px'
    };
    const InputWithLabel = FormItemProvider(Input);
    const { idp_metadata_url, idp_metadata, idp_properties, type, uploadPlaceholder } = this.state;
    let placeholder = 'URL pointing to metadata ';
    if (type === Type.details.name) {
      placeholder = 'Address of the Identity Provider';
    }
    if (type === Type.url.name) {
      return (
        <StackingLayout>
          <InputWithLabel
            label="Metadata url that provides IDP details"
            element={
              <Input id="idp_metadata_url" name="idp_metadata_url"
                placeholder={ placeholder }
                onChange={ this.handleChange }
                value={ idp_metadata_url }
                type="email"
              />
            }
            subLabel="This must match with your SAML providers url"
            helpText=""
          />
        </StackingLayout>
      );
    } else if (type === Type.metadata.name) {
      //  <input className="fileupload" type="file" onChange={ this.handleOnMetadataUpload } />
      return (
        <StackingLayout>
          <Upload placeholder={ uploadPlaceholder } onChange={ this.handleOnMetadataUpload } />
          <FormItemInput id="idp_metadata" name="idp_metadata" label=""
            onChange={ this.handleChange } value={ idp_metadata }
            type="textarea"
            rows="10"
            placeholder="Metadata in xml format with IDP details" />
        </StackingLayout>
      );
    } else if (type === Type.details.name) {
      return (
        <StackingLayout style={ style }>
          <FormItemInput id="idp_properties.idp_url" name="idp_properties.idp_url"
            label="URL of the Identity provider"
            onChange={ this.handleChange } value={ idp_properties.idp_url }
            placeholder="URL of the Identity provider" />
          <FormItemInput id="idp_properties.login_url" name="idp_properties.login_url"
            label="Login URL" subLabel="(required)"
            onChange={ this.handleChange } value={ idp_properties.login_url }
            placeholder="User log in to IDP with this URL." />
          <FormItemInput id="idp_properties.logout_url" name="idp_properties.logout_url"
            label="Logout URL"
            onChange={ this.handleChange } value={ idp_properties.logout_url }
            placeholder="Optional Logout URL for the Identity Provider" />
          <FormItemInput id="idp_properties.error_url" name="idp_properties.error_url"
            label="Error URL"
            onChange={ this.handleChange } value={ idp_properties.error_url }
            placeholder="Optional URL to redirect users if there is an error during SAML login" />
          <FormItemInput id="idp_properties.redirect_url" name="idp_properties.redirect_url"
            label="Redirect URL"
            onChange={ this.handleChange } value={ idp_properties.redirect_url }
            placeholder="Optional URL to be redirected to by IDP." />
          <FormItemInput id="idp_properties.certificate" name="idp_properties.certificate"
            label="Certificate" type="text" subLabel="(required)"
            onChange={ this.handleChange } value={ idp_properties.certificate }
            placeholder="Certificate for verification" />
          <InputWithLabel
            label="Name ID Policy Format"
            element={
              <Select selectOptions={ policyFormat }
                selected={ idp_properties.name_id_policy_format }
                placeholder="Select..."
                name="idp_properties.name_id_policy_format" onChange={ this.handleChange } />
            }
          />
        </StackingLayout>
      );
    }
  }

  handleTypeRadioOnChange(event) {
    this.setState({
      ...Object.assign({}, this.state, { [`${event.target.name}`]: event.target.value })
    });
  }
  handleChange = (event) => {
    const { requiredFields } = this.props;
    let value = event.target.value || '';
    const name = event.target.name;
    if (event.target.name === 'custom_attr') {
      value = value.split(',');
    }
    if (Object.keys(requiredFields).includes(event.target.name) && value.length < 1) {
      this.state.validation.push(event.target.name);
    }
    let idp_properties;
    if (name.startsWith('idp_properties')) {
      idp_properties = Object.assign({}, this.state.idp_properties,
        { [name.split('.')[1]]: value });
      this.setState({
        ...Object.assign({}, this.state, { idp_properties })
      });
    } else {
      this.setState({
        ...Object.assign({}, this.state, { [`${event.target.name}`]: value })
      });
    }
    logger('AddProvider.handleChange ', this.state.validation);
  }

  onHandleBeforeUpload(file, fileList) {
    logger('onHandleBeforeUpload ', file);
    return false;
  }

  handleOnClick = (evt) => {
    // logger('AddProvider', evt, 'Register a new provider');
    const body = this.getBody();
    // console.log('CREATE SAML PROVIDER::' + JSON.stringify(body));
    const request = {
      passthrough: false,
      endpoint: 'ADD_SAML_IDP',
      body
    };

    this.props.apiFetchRequest([request, { endpoint: 'GET_SAML_IDENTITY_PROVIDERS' }]);
    this.props.handleModalClick('add-provider', request);
  }

  getFooter() {
    const footer = (
      <div>
        <Button key="submit"
          type="primary"
          htmlType="submit"
          onClick={ this.handleOnClick } >
          Add Provider
        </Button>
      </div>
    );

    return footer;
  }
  getBody() {
    const { type, idp_metadata_url, email_attr, username_attr, saml_idp_name,
      idp_metadata, idp_properties, groups_attr } = this.state;
    let body;
    if (type === Type.url.name) {
      body = {
        saml_idp_name,
        idp_metadata_url,
        email_attr,
        username_attr,
        // custom_attr, disabled for now
        groups_attr
      };
    } else if (type === Type.metadata.name) {
      body = {
        saml_idp_name,
        email_attr,
        username_attr,
        idp_metadata: btoa(idp_metadata)
      };
    } else if (type === Type.details.name) {
      body = {
        saml_idp_name,
        email_attr,
        username_attr,
        idp_properties
      };
    }

    return body;
  }

  /**
   * Reads the files content and fills the textarea
   * @param {object} event file intype change event.
   * @return {boolean} false
   */
  handleOnMetadataUpload(event) {
    let contents = '';
    const file = event.target.files[0];
    logger('File upload status is - ', file);
    const reader = new FileReader();
    const _self = this;
    // Closure to capture the file information.
    reader.onload = ((theFile) => {
      return (e) => {
        contents = e.target.result;
        _self.setState({ idp_metadata: contents,
          uploadPlaceholder: theFile.name });
      };
    })(file);
    // Read in the image file as a data URL.
    reader.readAsText(file);
    return false;
  }

}

export default AddProvider;
