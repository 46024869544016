// Render Prop
import React from 'react';

import { withFormik } from 'formik';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Button, StackingLayout, Modal } from 'prism-reactjs';

import { apiFetchRequest, clearCacheForEndpoint } from '../../actions';
import { formikProps } from '../../utils';
import { FRInputPlusLabel, FRSelect } from '../../components/Common';

class CreateLocalUserForm extends React.Component {

  static propTypes = formikProps;

  render() {
    const { values, errors, setFieldValue, setFieldTouched, handleSubmit,
      handleModalClick, mode, supportedLocales } = this.props;
    const fp = {
      values,
      errors,
      setFieldValue,
      setFieldTouched
    };

    return (
      <Modal
        visible={ true }
        title="Create Local User"
        contentClassName="iam-modal-content"
        footer={ this.getFooter() }
        onCancel={ handleModalClick }
        width={ 500 }
      >
        <div className="CreateLocalUser">
          <form onSubmit={ handleSubmit }>
            <StackingLayout>
              <FRInputPlusLabel label="Username"
                placeholder="Unique name ss for the local account"
                disabled={ mode === 'update' }
                name="username"
                { ...fp } />
              <FRInputPlusLabel label="Display Name"
                name="display_name"
                { ...fp } />
              <FRInputPlusLabel label="Email"
                placeholder="An email address for the service account"
                name="additional_attributes.email_address"
                { ...fp } />
              <FRSelect label="Locale"
                name="locale"
                selectOptions={ supportedLocales }
                { ...fp } />
              {
                (mode === 'create' || mode === 'update') &&
                <FRInputPlusLabel label="Password" type="password"
                  name="password"
                  { ...fp } />
              }
              {
                (mode === 'update') &&
                <FRInputPlusLabel label="Confirm Password" type="password"
                  name="confirm_password"
                  { ...fp } />
              }
            </StackingLayout>
          </form>
        </div>
      </Modal>
    );
  }

  getFooter() {
    const { handleSubmit, mode } = this.props;
    const footer = (
      <div>
        { (mode === 'xxxupdate') &&
        <Button key="password_reset"
          onClick={ handleSubmit } >
          Save
        </Button>

        }
        <Button key="submit"
          type="primary"
          htmlType="submit"
          onClick={ handleSubmit } >
          Save
        </Button>
      </div>
    );

    return footer;
  }

}

const formikEnhancer = withFormik({
  enableReinitialize: true,
  mapPropsToValues: (props) => {
    const { uuid, username, type = 'local', display_name, locale = 'en_US',
      password, additional_attributes = { email_address: '' } } = props.GET_USER || {};
    return {
      uuid,
      username,
      type,
      display_name,
      locale,
      password,
      confirm_password: '',
      additional_attributes
    };
  },
  initialValues:{ email: '',
    password: '' },
  handleSubmit: (values, formikBag) => {
    const { setSubmitting } = formikBag;
    let body = values;
    let endpoint = 'CREATE_USER';
    if (formikBag.props.mode === 'update') {
      body = Object.assign({}, formikBag.user, values);
      endpoint = 'UPDATE_USER';
    }
    const request = {
      endpoint,
      body
    };
    formikBag.props.apiFetchRequest(request);
    setSubmitting(false);
    // formikBag.props.history.push(formikBag.props.locationAfterSubmit);
    formikBag.props.handleModalClick('close');
  },
  displayName: 'BasicForm',
  validate: (values) => {
    const errors = {};
    if (!values.username) {
      errors.name = 'Required';
    }

    return errors;
  }
})(CreateLocalUserForm);

const CreateLocalUser = connect(
  state => state.api,
  dispatch => bindActionCreators({ apiFetchRequest,
    clearCacheForEndpoint }, dispatch)
)(formikEnhancer);

export default CreateLocalUser;
