export const identitiesNavigation = {
  title: 'Identities',
  subTitle: 'Total Users',
  defaultSelectedItem: '1',
  leftMenuItems: [
    {
      label: 'Users',
      key: 1,
      replace: true,
      route: '/identities/users'
    },
    // {
    //   label: 'Groups',
    //   key: 2,
    //   replace: true,
    //   route: '/identities/groups'
    // },
    {
      label: 'Service Accounts',
      key: 3,
      replace: true,
      route: '/identities/serviceusers'
    }

  ]

};

