import * as React from 'react';
// import differenceInCalendarDays from 'date-fns/difference_in_calendar_days';
import distanceInWordsStrict from 'date-fns/formatDistanceStrict';
import { eoLocale } from 'date-fns/locale/eo';
// import parse from 'date-fns/parse';
import { Link, StackingLayout } from 'prism-reactjs';

/**
 *       `${distanceInWordsStrict(parse(rowData.expiry_time),
        parse(rowData.created_time), { locale: eoLocale })}`

 */
const CREATE_KEY = 'CREATE_KEY';
const REVOKE_KEY = 'REVOKE_KEY';
const DOWNLOAD_PRIVATE_KEY = 'DOWNLOAD_PRIVATE_KEY';
const DOWNLOAD_CERTIFICATE = 'DOWNLOAD_CERTIFICATE';
// const DATE_FORMAT = 'yyyy-MM-dd hh:mm:ss.SSSS XXXXX';

export const manageserviceAccountKeysColumns = (onRowActionClick) => [
  {
    title: 'Serial Number',
    key: 'serial_number'
  }, {
    title: 'Time Remaining',
    key: 'expiry_time',
    render: (celldata, rowData) => `${distanceInWordsStrict(
      new Date(rowData.expiry_time.substr(0, 19)),
      new Date(rowData.created_time.substr(0, 19)),
      { locale: eoLocale })}`
  },
  {
    title: 'Actions',
    key: 'action',
    className: 'action-col',
    textAlign: 'right',
    render: (cellData, rowData) => {
      const editLink = [];
      if (rowData.certificate) {
        editLink.push(<Link key="download" data-action={ DOWNLOAD_PRIVATE_KEY }
          href="" download={ `${rowData.uuid}-key.pem` }
          data-uuid={ rowData.uuid }
          onClick={ onRowActionClick }>Download Key</Link>);
        editLink.push(<Link key="copy" data-action={ DOWNLOAD_CERTIFICATE }
          href="" download={ `${rowData.uuid}-cert.pem` }
          data-uuid={ rowData.uuid }
          onClick={ onRowActionClick }>Download Certificate</Link>);
      }
      editLink.push(<Link key="revoke" data-action={ REVOKE_KEY }
        data-uuid={ rowData.uuid }
        onClick={ onRowActionClick }>Revoke</Link>);
      return (
        <StackingLayout itemDisplay="block">
          { editLink }
        </StackingLayout>
      );
    }
  }
];

export const manageserviceAccountKeysActions = {
  primary: [
    {
      key: 'generate_new',
      icon: 'plus',
      value: 'Generate Certificate'
    }
  ]
};

export const manageserviceAccountKeysPagination = {
  total: 0,
  pageSize: 50,
  currentPage: 1,
  pageSizeOptions: [50, 100]
};

export const manageserviceAccountKeysRowAction = {
  actions: [
    {
      key: 'regenarate',
      value: 'Regenerate'
    },
    {
      key: 'revoke',
      value: 'Revoke'
    }
  ],
  getPopupContainer: () => document.querySelector('.body-wrapper')
};

export const manageserviceAccountKeysLocalActions = {
  CREATE_KEY,
  REVOKE_KEY,
  DOWNLOAD_PRIVATE_KEY,
  DOWNLOAD_CERTIFICATE
};
