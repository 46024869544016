import * as React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { Alert, Button, FlexLayout,
  StackingLayout, Divider, Paragraph,
  Link, Title, Loader } from 'prism-reactjs';

import { LDAP_TYPE, LOCAL_TYPE, PENDING, REFRESH_ERR_CODE } from '../../constants';
import { HANDLE_ERROR, HOME_URL } from '../../constant';
import { logger,
  getErrorMsg,
  showErrorMsg,
  removeSessionValue,
  setSessionValue,
  getSessionValue,
  setWelcomeBannerHasTriggered,
  getWelcomeBannerHasTriggered,
  getVersionString,
  getLoginCustomObj,
  forceRedirect,
  authRequestExist,
  isUserLoggedin
} from '../../utils';
import {
  apiFetchRequest,
  hideWelcomeBannerAction,
  getSystemConfigAction,
  getPrismVersionAction
} from '../../actions';
import { LoginLayout } from '../../components/Common';

// TO-DO: CHANGE LATER TO USE ntnx-I18n library
import getI18nService from '../../i18n/I18n';
import WelcomeBanner from '../../components/WelcomeBanner';
import UserGuideLink from '../UserGuideLink';
import iamApis from '../../api/iam-apis';
import './LoginPage.less';

const i18n = getI18nService();

class Providers extends React.Component {

  static propTypes = {
    pending: PropTypes.bool,
    history: PropTypes.object,
    connectors: PropTypes.object,
    customLoginArray: PropTypes.array,
    location: PropTypes.object,
    apiFetchRequest: PropTypes.func,
    noProvidersMessage: PropTypes.string,
    systemConfig: PropTypes.object,
    softwareVersion: PropTypes.string,
    hideWelcomeBannerAction: PropTypes.func,
    getSystemConfigAction: PropTypes.func,
    getPrismVersionAction: PropTypes.func
  }
  static defaultProps = {
    message: i18n.t('Providers.message'),
    noProvidersMessage: i18n.t('Providers.noProviderMessage')
  };

  constructor(props) {
    super(props);
    this.state = {
      search: props.location.search,
      showError: showErrorMsg(props.location.search),
      errorCode: props.location.search
    };
  }

  componentDidMount() {
    const { getSystemConfigAction: getSystemConfig,
      getPrismVersionAction: getPrismVersion } = this.props;

    // check and route to login page or providers page.
    logger('ActionsButtons', 'check and route to login page or providers page.');

    // Make get system config and connector API calls
    getSystemConfig(iamApis.GET_SYSTEM_CONFIG, this.state.search);
    getPrismVersion(iamApis.GET_PRISM_VERSION);
  }

  /**
   * New lifecycle function to prevent render UI before redirect
   * @param {Object} nextProps - Next Props
   * @param {Object} nextState - Next State
   * @returns {boolean} - whether re-render the component
   */
  shouldComponentUpdate(nextProps, nextState) {
    let updateRender = false;
    let { connectors, pending } = nextProps;
    let { errorCode } = nextState;

    // Don't update render() if getConnector pending
    if (!pending) {
      // Make sure the connectors isn't empty object
      if (connectors && (connectors.result.length > 0)) {
        // Get redirect_url from getRedirectUrl()
        const redirect_url = this.getRedirectUrl(connectors, errorCode);

        // Redirect other url before render this component
        if (redirect_url) {
          forceRedirect(redirect_url);
        } else {
          // Shouldn't redirect and update the render()
          updateRender = true;
        }
      }
    }
    return updateRender;
  }

  /**
   * Closes welcome banner and launches request for connectors
   */
  handleCloseWelcomeBanner = () => {
    const { hideWelcomeBannerAction: hideWelcomeBanner } = this.props;
    hideWelcomeBanner('GET_SYSTEM_CONFIG', false);
    // Has welcome banner exist;
    this.setWelcomeBannerStatus(true);
  };

  render() {
    const { connectors, systemConfig, pending, customLoginArray, softwareVersion } = this.props;
    const { showError, errorCode } = this.state;
    const localConn = this.getConnector(LOCAL_TYPE);
    const customLoginObj = getLoginCustomObj(customLoginArray);

    const connectorsList = get(connectors, 'result', []);
    const localUrl = get(localConn, 'url', '');
    const localName = get(localConn, 'name', '');
    const localType = get(localConn, 'type', '');

    const errorMsg = getErrorMsg(errorCode);

    const providersForm = (
      <StackingLayout itemSpacing="30px" itemDisplay="block" className="providerpage-form">
        <StackingLayout className="loginpage-form-content">
          {
            showError &&
            <Alert message={ errorMsg }
              data-test="ant-alert-message"
              className="login-alert"
              type={ Alert.TYPE.ERROR }
              showCloseIcon={ false } />
          }
          <StackingLayout className="loginpage-btns-wrapper" itemSpacing="20px">
            { this.getChildrenContent(connectorsList) }
          </StackingLayout>
          <FlexLayout alignItems="center" justifyContent="center">
            <Divider />
            <Paragraph className="no-wrap" type="secondary">
              {i18n.t('Vocabulary.or')}
            </Paragraph>
            <Divider />
          </FlexLayout>
        </StackingLayout>
        <Link className="iam-center-text"
          id="local-link"
          data-test="providersLinkLocalLogin"
          onClick={ this.handleGoToLogin(localUrl, localName, localType) }
        >
          {i18n.t('Providers.nutanixProviderLoginMessage')}
        </Link>
        {
          getWelcomeBannerHasTriggered() ?
            null :
            this.getBanner(systemConfig)
        }
        <UserGuideLink version={ getVersionString(softwareVersion) } />
      </StackingLayout>
    );

    return !pending ? (
      <LoginLayout
        form={ providersForm }
        customLoginEle={ customLoginObj }
        leftTitle={ i18n.t('LoginLayout.reimagineText') }
      />
    ) : <Loader tip="Loading..." overlay={ true } />;
  }


  /**
   * Decided if redirect and get redirect_url for following situations:
   * 1. 412 error
   * 2. Only local connector
   * 3. Only local + one LDAP connector
   * @param {Object} connectors - connectors object from next props
   * @param {String} errorCode - errorCode from state
   * @returns {String} - return redirect_url
   */
  getRedirectUrl(connectors, errorCode) {
    const localConn = this.getConnector(LOCAL_TYPE);
    const adConnector = this.getConnector(LDAP_TYPE);

    // Get auth_req_exists flag from connectors API
    const authReqExist = authRequestExist(connectors);
    const isUserLoggedIn = isUserLoggedin(connectors);
    const connectorsList = get(connectors, 'result', []);
    const localUrl = get(localConn, 'url', '');
    const localName = get(localConn, 'name', '');
    const localType = get(localConn, 'type', '');

    // Keep HANDLE_ERROR setter only call once
    const isSameError = this.sameErrorAsPrev(errorCode);
    /**
     * if IAM AuthN request cookie isn't exist(auth_req_exists == false),
     * or if user already logged in is_user_loggedin === true,
     * then user will force redirect back to Prism landing page.
     */
    if (!authReqExist || isUserLoggedIn) {
      return HOME_URL;
    }

    /**
     * if IAM AuthN backend session has expired, it will provider page from redirect with "ec=ERR-412"
     * if there isn't 412 before, then will send
     * user back to Prism landing page.
     */
    if (REFRESH_ERR_CODE && errorCode.includes(REFRESH_ERR_CODE)) {
      this.setToLocal(localUrl, localName, localType);

      if (!isSameError) {
        // Redirect UI to host URL when it get Error Code on first time.
        return HOME_URL;
      }
    }

    const errorMsg = getErrorMsg(errorCode);

    /**
     * if connectorsList has only a local connector it would redirect to the login screen
     * without showing the providers screen
     */
    if (connectorsList.length === 1 && localConn) {
      // redirects to login screen, window.location.href is used because we need to executed the
      // connector url in order to launch an auth/<connectorId> to tell the backend with which connectot the
      // the user is trying to login - redirect browser to *localUrl*
      // this.state.search to contain error message
      if (this.state.search === '') {
        this.setToLocal(localUrl, localName, localType);
        return localUrl;
      } else if (errorMsg && !isSameError) {
        // first time get Error Code
        this.setToLocal(localUrl, localName, localType);
        // Only include state.search as error code when there isn't same error get before.
        return (localUrl + this.state.search);
      }
    }

    /**
     * if connectors list has 2 connectors local and ldap connector should redirect to the login screen
     * without showing the providers screen but with option to change login between local and ldap
     */
    if (connectorsList.length === 2 && (localConn && adConnector)) {
      const name = get(adConnector, 'name', '');
      const type = get(adConnector, 'type', '');
      const url = get(adConnector, 'url', '');
      this.setToLocal(url, name, type);

      // this.state.search to contain error message
      if (this.state.search === '') {
        return url;
      } else if (errorMsg && !isSameError) {
        // Only attach error message when never get this error before
        return (url + this.state.search);
      }
    }

    // Don't redirect;
    return null;
  }

  /**
   *  Fetch for connectors
  */
  handleConnectorsRequest = () => {
    const { apiFetchRequest: getConnectors } = this.props;
    getConnectors({ endpoint: 'GET_CONNECTORS' });
  }

  /**
   * Closes welcome banner
   */
  handleCloseWelcomeBanner = () => {
    const { hideWelcomeBannerAction: hideWelcomeBanner } = this.props;
    hideWelcomeBanner('GET_SYSTEM_CONFIG', false);
    // Has welcome banner exist;
    setWelcomeBannerHasTriggered(true);
  };

  /**
  * Get Welcome banner component from systemConfig
  * @param {String} systemConfig - system configure object
  * @returns {HTML} - return welcome banner to display
  */
  getBanner(systemConfig) {
    const bannerStatus = get(systemConfig, 'welcome_banner_status')
      && !getWelcomeBannerHasTriggered();
    const bannerHTMLContent = get(systemConfig,
      'welcome_banner_content');

    return (<WelcomeBanner
      { ...{ bannerHTMLContent,
        bannerStatus } }
      onHandleCloseBanner={ this.handleCloseWelcomeBanner }
    />);
  }

  /**
   *  Get Children for provider display
   * @param {Array} connectorsList - list of connectors
   * @returns {HTML} - return content to display
  */
  getChildrenContent(connectorsList) {
    if (!connectorsList) {
      return null;
    }
    const { noProvidersMessage } = this.props;
    // No connectors
    if (!connectorsList.length) {
      return <Title size="h4">{noProvidersMessage}</Title>;
    }
    // list of connector buttons
    return connectorsList.map((connector, index) => {
      return connector.type !== 'local' && (
        <Button
          fullWidth={ true }
          data-test={ `providersButton${connector.name}` }
          data-href={ connector.url }
          id={ connector.id } key={ index }
          onClick={
            this.handleGoToLogin(connector.url, connector.name, connector.type)
          }
        >
          {`Log in with ${connector.name}`}
        </Button>
      );
    });
  }

  /**
   * Redirects to login screen for connector selected
   * and sets connector in localStorage
   * @param {String} url - url string
   * @param {String} name - connector name
   * @param {String} type - connector type
   * @returns {void}
   *
  */
  handleGoToLogin = (url, name, type) => () => {
    this.setToLocal(url, name, type);

    // Clear HANDLE_ERROR from sessionStorage when user click link
    removeSessionValue(HANDLE_ERROR);

    window.location.href = url;
  }

  /**
   * Get connector from connectors list
   * @param {String} type - connector type
   * @returns {Object} Connector object
   */
  getConnector = (type) => {
    const { connectors } = this.props;
    const connectorsList = get(connectors, 'result', []);
    return connectorsList.find((connector) => connector.type === type);
  }

  /**
   * Sets connector in localStorage
   * @param {String} url - connector url
   * @param {String} name - connector name
   * @param {String} type - connector type
   * @returns {void}
   */
  setToLocal = (url, name, type) => {
    localStorage.setItem('connector', JSON.stringify({ name,
      type,
      url
    }));
  }

  /**
   * Check if the error has been get before
   * @param {String} error - errorCode from URL parameter
   * @returns {Boolean} is same error like before
   */
  sameErrorAsPrev = (error) => {
    const existError = getSessionValue(HANDLE_ERROR);
    // If there is error exist on previous call
    if (existError !== error) {
      // Set flag for new type of error
      setSessionValue(HANDLE_ERROR, error);
    }
    // Return false if error is different compare to before
    return existError === error;
  }
}

const mapStateToProps = (state) => {
  return {
    pending: state.api[PENDING],
    systemConfig: state.api.GET_SYSTEM_CONFIG,
    connectors: state.api.GET_CONNECTORS,
    softwareVersion: state.api.PRISM_VERSION,
    customLoginArray: state.api.CUSTOM_LOGIN
  };
};

export default connect(mapStateToProps, {
  apiFetchRequest,
  hideWelcomeBannerAction,
  getSystemConfigAction,
  getPrismVersionAction
})(Providers);
