export const LANGUAGE_BY_LOCALE = {
  af: 'Afrikaans',
  sq: 'Albanian',
  am: 'Amharic',
  ar: 'Arabic',
  hy: 'Armenian',
  as: 'Assamese',
  ca: 'Catalan',
  zh: 'Chinese',
  en_GB: 'English (United Kingdom)',
  en_US: 'English (United States)',
  en: 'English',
  fi: 'Finnish',
  fr: 'French',
  de: 'German',
  ja_JP: 'Japanese (Japan)',
  ja: 'Japanese',
  ko_KR: 'Korean (South Korea)',
  ko: 'Korean',
  fa_IR: 'Persian (Iran)',
  fa: 'Persian',
  pl_PL: 'Polish (Poland)',
  pl: 'Polish',
  pt_PT: 'Portuguese (Portugal)',
  pt: 'Portuguese',
  es_ES: 'Spanish (Spain)',
  es: 'Spanish',
  tr_TR: 'Turkish (Turkey)',
  tr: 'Turkish'
};

let supportedLocales = null;

/**
 * Return locale as an array of label and value objects for ropdowns and selects to user.
 * @returns array
 */

export const localeOptions = () => {
  if (!supportedLocales) {
    supportedLocales = [];
    for (const [key, value] of Object.entries(LANGUAGE_BY_LOCALE)) {
      supportedLocales.push({
        label: value,
        value: key
      });
    }
  }
  return supportedLocales;
};
