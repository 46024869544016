// import debug from 'debug';

import queryString from 'query-string';

// import { apiFetchRequest } from '../actions';
import {
  apiRoot
} from '../config';
import IamApis from './iam-apis';
import { logger } from '../utils';
// import { getAuthService } from './auth-service';

class API {

  isMockMode = false;

  constructor(isMockMode = false) {
    this.isMockMode = isMockMode;
    this.invoke = this.invoke.bind(this);
  }

  async invoke(request) {
    if (this.isMockMode) {
      const timeout = (ms) => {
        return new Promise(resolve => setTimeout(resolve, ms));
      };
      await timeout(1000);

      return {
        json: {
          application: 'iam'
        },
        statusCode: 200
      };
    }

    const endpoint = IamApis[request.endpoint];
    let url = endpoint.url;

    const body = request.body;
    if (url.split(':').length > 0) {
      // url has params to fill in.
      // url could be with 2 params too /v1/api/dir/orgs/:org_id/users/:user_id
      let params = url.split(/[:]/).slice(1);
      if (params.length > 0) {
        params = params.map((item) => item.split('/')[0]);
        params.forEach((param) => {
          // look for value within body
          if (!body || !body[param]) {
            logger(`ERROR apiFetch: URL ${url} requires parameter ${param} in the payload`);
          }
          url = url.replace(`:${param}`, String(body[param]));
          // to avoid duplication in body
          delete body[param];
        });
      }
    }

    url = `${apiRoot}${url}`;

    const baseheadersxx = {
      Accept: '*/*',
      'Accept-Encoding': 'gzip, deflate, br',
      'Cache-Control': 'no-cache',
      Connection: 'keep-alive'
    };
    logger('iam:fetch', baseheadersxx);

    // const baseHeaders = {
    // 'Content-Type': 'application/json'
    // };

    let headers = Object.assign({}, endpoint.headers, request.headers);

    // const authService = getAuthService();

    // const user = await authService.getUser();

    let authResponse = localStorage.getItem('id_tokenauth_response');
    if (authResponse) {
      authResponse = JSON.parse(authResponse);
    }

    // @TODO we set the id_token in localStore for now in main index.tsx
    const idToken = (authResponse && authResponse.id_token) ? authResponse.id_token : null;
    if (idToken) {
      headers = Object.assign(headers, {
        Authorization: `Bearer ${idToken}`
      });
    }

    let response;
    try {
      const fetchArgs = {
        // credentials: 'include',
        headers,
        method: endpoint.method,
        redirect: 'manual'
      };

      if (endpoint.method.toLocaleLowerCase() === 'get') {
        url = `${url}?${queryString.stringify(request.body)}`;
      } else if (headers['Content-Type'] === 'multipart/form-data') {
        delete fetchArgs.headers['Content-Type'];
        const data = new FormData();
        data.append('files', request.body);
        fetchArgs.body = data;
      } else {
        fetchArgs.body = (body) ? JSON.stringify(body) : null;
      }

      if (request.passthrough) {
        // no need to do anything its just passthrough
        logger('pasthough::', url, JSON.stringify(fetchArgs));
        return;
      }

      const result = await fetch(url, fetchArgs);
      const contentType = result.headers.get('content-type');
      if (contentType && contentType.indexOf('application/json') !== -1) {
        // successful cross-domain connect/ability
        const resp = await result.json();
        response = {
          endpoint: request.endpoint,
          fetchRes: result,
          json: resp,
          statusCode: result.status
        };
      } else if (result.status === 200 && contentType && contentType.indexOf('text/plain') !== -1) {
        const resp = await result.text();
        response = {
          endpoint: request.endpoint,
          fetchRes: result,
          json: JSON.parse(resp),
          statusCode: result.status
        };
      } else if (result && result.ok && result.status === 204) {
        response = {
          endpoint: request.endpoint,
          fetchRes: result,
          json: {
            status: '204',
            ok: true
          },
          statusCode: result.status
        };
      } else if (result && result.type && result.type === 'opaqueredirect') {
        response = {
          json: {
            details: 'Your session expired. Please refresh the page.',
            endpoint: request.endpoint,
            error_code: 'RECEIVED_LOGIN_REDIRECT',
            severity: 'error'
          }
        };
      } else {
        response = {
          json: {
            details: 'Either server not authenticated or error on server',
            endpoint: request.endpoint,
            error_code: 'INVALID_SERVER_RESPONSE',
            severity: 'error'
          }
        };
      }
    } catch (e) {
      // debug('iam:fetch', `Error in fetching on url ${endpoint.url}`);
      response = {
        json: {
          details: `Error in connecting to server ${e}`,
          endpoint,
          error_code: 'FETCH_COMMUNICATION_ERROR',
          severity: 'error'
        }
      };
    }

    return response;
  }

}

export default new API();
