if (!window.iam) {
  window.iam = {
    oauth: {}
  };
}

export const oidc = window.iam && window.iam.oauth;

/*
export const oidc = {
  stsAuthority: 'http://10.4.146.133:5556/iam',
  clientId: 'example-app',
  clientRoot: 'http://10.4.146.132:5559/',
  clientScope: 'openid profile email'
};
*/

