import getI18nService from '../../i18n/I18n';

const i18n = getI18nService();

export const usersListColumns = [
  {
    title: i18n.t('UsersList.columns.name'),
    key: 'username'
  }, {
    title: i18n.t('UsersList.columns.type'),
    key: 'type',
    render: (celldata, rowData) => rowData.idp_name || celldata
  }, {
    title: i18n.t('UsersList.columns.lastLogin', { timezone: Intl.DateTimeFormat().resolvedOptions().timeZone }),
    key: 'last_login_time',
    render: (celldata, rowData) => new Date(celldata).toLocaleString()
  }
];

export const usersListActions = {
  primary: [
    {
      key: 'create',
      value: i18n.t('UsersList.actions.create')
    }
  ],
  secondary: [
    /* {
      key: 'activate',
      value: 'Activate',
      selectionCount: 1
    },
    {
      key: 'deactivate',
      value: 'De-Activate',
      selectionCount: 1
    },
    */
    {
      key: 'update',
      value: i18n.t('Vocabulary.Update'),
      selectionCount: 1
    }
  ]
};

export const usersPagination = {
  total: 0,
  pageSize: 5,
  currentPage: 1,
  pageSizeOptions: [5, 10, 15, 25, 50]
};

export const usersListRowAction = {
  actions: (rowData) => {
    return [];
    /*
    if (rowData.active) {
      return [{
        key: 'deactivate',
        value: 'Deactivate'
      }];
    }
    return [{
      key: 'deactivate',
      value: 'Deactivate'
    }];
    */
  },
  getPopupContainer: () => document.querySelector('.body-wrapper')
};
