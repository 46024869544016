import * as React from 'react';
import PropTypes from 'prop-types';
// import debug from 'debug';
import { ContainerLayout, Table } from 'prism-reactjs';

import { usersListColumns, usersPagination, usersListRowAction,
  usersListActions, localeOptions } from '../../metadata';
import { logger, bindFunctions, getModelByKey } from '../../utils';
import { Actions } from '../../components/Common';
import CreateLocalUser from './CreateLocalUser.jsx';
import getI18nService from '../../i18n/I18n';

const i18n = getI18nService();

class UsersList extends React.Component {

  static propTypes = {
    actions: PropTypes.object,
    columns: PropTypes.array,
    api: PropTypes.object,
    apiFetchRequest: PropTypes.func,
    apiFetchRequestThrottled: PropTypes.func,
    clearCacheForEndpoint: PropTypes.func,
    supportedLocales: PropTypes.array
  }

  static defaultProps = {
    actions: Object.assign({}, usersListActions),
    columns: usersListColumns,
    users: {},
    supportedLocales: localeOptions()
  }

  constructor(props) {
    super(props);
    this.state = {
      pagination: Object.assign({}, usersPagination),
      rowSelection: {
        selected: []
      },
      showCreateUserModal: false,
      modalMode: 'create'
    };
    bindFunctions(['handleChangePagination', 'handleAction', 'isActionDisabled',
      'handleRowAction', 'handleChangeRowSelection', 'clearApiCache', 'handleChangeSearch', 'onHandleCreateUserModalClick'], this);
    this.rowAction = Object.assign({}, usersListRowAction, { onRowAction: this.handleRowAction });
    this.search = {
      inputSearchProps: {
        placeholder: i18n.t('Vocabulary.typeToFilter'),
        onChange: this.handleChangeSearch
      }
    };
  }

  componentDidMount() {
    logger('iam:UsersList', 'check and route to login page or providers page.');
    const { pagination } = this.state;
    this.props.apiFetchRequest([{ endpoint: 'GET_USERS',
      body: {
        length: pagination.pageSize,
        offset: (pagination.currentPage - 1) * pagination.pageSize
      }
    }, { endpoint: 'GET_CONNECTORS' }]);
  }
  componentWillUnmount() {
    this.clearApiCache();
  }

  clearApiCache() {
    this.props.clearCacheForEndpoint({ endpoint: 'GET_USERS' });
    this.props.clearCacheForEndpoint({ endpoint: 'UPDATE_USER_STATUS' });
  }

  render() {
    const { columns, api, supportedLocales } = this.props;
    const { pagination, showCreateUserModal, modalMode } = this.state;
    const { GET_USER, GET_USERS, GET_CONNECTORS = {} } = api;
    const totalCount = (GET_USERS) ? GET_USERS.total_matches : 0;
    let data = (GET_USERS && GET_USERS.result) ? GET_USERS.result : [];
    data = this.fillIdpName(data, GET_CONNECTORS.result || []);
    const title = i18n.t('UsersList.title', { totalCount });
    const _paginaion = Object.assign({}, pagination);
    _paginaion.total = totalCount || data.length;
    // rowAction={ this.rowAction } DISABLED FOR NOW
    // onChangeRowSelection={ this.handleChangeRowSelection }
    // rowSelection={ this.state.rowSelection }

    return (
      <ContainerLayout className="UserList" padding="10px">
        { this.getActions() }
        <Table rowKey="uuid" oldTable={ false } dataSource={ data } columns={ columns }
          topSection={ { title } }
          pagination={ _paginaion }
          onChangePagination={ this.handleChangePagination }
          onChangeRowSelection={ this.handleChangeRowSelection }
          rowSelection={ this.state.rowSelection }
          search={ this.search }
          structure={ {
            paginationPosition: {
              top: true,
              bottom: true
            }
          } } />

        {
          (showCreateUserModal) && (
            <CreateLocalUser handleModalClick={ this.onHandleCreateUserModalClick } mode={ modalMode }
              supportedLocales={ supportedLocales } user={ GET_USER } />
          )
        }

      </ContainerLayout>
    );
  }

  fillIdpName(users, connectors) {
    let provider;
    for (const user of users) {
      if (user.idp_id) {
        provider = getModelByKey('id', user.idp_id, connectors);
        if (provider) {
          user.idp_name = provider.name;
        }
      }
    }

    return users;
  }


  getActions() {
    const onHandleAction = this.handleAction;
    return (<Actions cssClass="UserList_Actions"
      handleAction={ onHandleAction }
      selectionCount={ this.state.rowSelection.selected.length }
      isActionDisabled={ this.isActionDisabled }
      actions={ this.props.actions } />);
  }

  isActionDisabled(action) {
    const { rowSelection } = this.state;
    let disabled = false;
    const user = (rowSelection.selected[0]) ? this.getRowModel(rowSelection.selected[0]) : null;
    if (action.key === 'activate' || action.key === 'deactivate') {
      if (rowSelection.selected.length !== 1) {
        disabled = true;
      } else {
        if (user && user.active && action.key === 'activate') {
          disabled = true;
        }
        if (user && !user.active && action.key === 'deactivate') {
          disabled = true;
        }
      }
    } else if (action.key === 'update') {
      if (rowSelection.selected.length !== 1) {
        disabled = true;
      } else if (user && user.type && user.type !== 'local') {
        disabled = true;
      }
    }
    return disabled;
  }

  getRowModel(uuid) {
    const { GET_USERS } = this.props.api;
    if (GET_USERS && GET_USERS.result) {
      for (const user of GET_USERS.result) {
        if (user.uuid === uuid) {
          return user;
        }
      }
    }
  }

  handleAction(event) {
    const { rowSelection } = this.state;
    const action = event.currentTarget.dataset.action;
    logger('iam:UserList.handleAction', action);
    const user = (action === 'update' && rowSelection.selected[0]) ? this.getRowModel(rowSelection.selected[0]) : null;
    if (action === 'create') {
      this.setState(Object.assign(this.state,
        { showCreateUserModal: true,
          modalMode: 'create' }));
    } else if (action === 'update') {
      this.setState(Object.assign(this.state,
        { showCreateUserModal: true,
          modalMode: 'update' }));
      if (user && user.uuid) {
        this.props.apiFetchRequest({
          endpoint: 'GET_USER',
          body: {
            uuid: user.uuid
          }
        });
      }
    } else if (action === 'activate' || action === 'deactivate') {
      this.handleRowAction(action, user);
    }
  }

  handleRowAction(key, rowData) {
    logger('iam:UserList::handleRowAction', `${key} : ${JSON.stringify(rowData)}`);
    if (key === 'activate' || key === 'deactivate') {
      this.props.apiFetchRequest({
        endpoint: 'UPDATE_USER_STATUS',
        body: {
          uuid: rowData.uuid,
          active: key === 'activate'
        }
      });
      this.props.apiFetchRequest({ endpoint: 'GET_USERS' });
    }
  }

  handleChangeRowSelection(rowSelection) {
    this.setState({
      rowSelection
    });
  }

  handleChangePagination(pagination) {
    logger('iam:UsersList:onHandleChangePagination', JSON.stringify(pagination));
    // set the state with new pagination.
    this.setState(Object.assign({}, this.state, { pagination }));
    this.props.apiFetchRequest(
      {
        endpoint: 'GET_USERS',
        body: {
          length: pagination.pageSize,
          offset: (pagination.currentPage - 1) * pagination.pageSize
        }
      }
    );
  }

  handleChangeSearch(event) {
    const text = event.currentTarget.value;
    logger('iam:UserList search ', text);
    const { pagination } = this.state;
    this.props.apiFetchRequestThrottled({
      endpoint: 'GET_USERS',
      body: {
        length: pagination.pageSize,
        offset: (pagination.currentPage - 1) * pagination.pageSize,
        filter: `username==${text}.*`
      }
    });
  }

  onHandleCreateUserModalClick(action, payload) {
    const { pagination } = this.state;
    if (typeof action !== 'string' || action === 'close') {
      this.setState(Object.assign(this.state,
        { showCreateUserModal: !this.state.showCreateUserModal }));
      this.props.apiFetchRequest(
        {
          endpoint: 'GET_USERS',
          body: {
            length: pagination.pageSize,
            offset: (pagination.currentPage - 1) * pagination.pageSize
          }
        }
      );
    }
  }

}

export default UsersList;
