import React from 'react';

import { FormItemInput } from 'prism-reactjs';
import { getDescendantProp } from '../../utils';

const FRInputPlusLabel = (args) => {
  const {
    values,
    errors,
    setFieldValue,
    setFieldTouched,
    name,
    ...rest
  } = args;

  const value = getDescendantProp(values, name, '.');

  return (<FormItemInput error={ errors.name } label="Name"
    name={ name }
    value={ value }
    onChange={ event => setFieldValue(name, event.target.value) }
    onBlur={ () => setFieldTouched(name) }
    helpText={ errors[name] }
    { ...rest } />
  );
};


export default FRInputPlusLabel;
