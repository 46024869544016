import * as constants from '../constants';

/**
 * Document it
 * @param {object} state - state
 * @param {object} action - action
 * @returns {object} - mutated state
 */
export default function application(state, action) {
  switch (action.type) {
    case constants.SHOW_TOASTER:
      return { ...state,
        toaster: action.toaster
      };
    case constants.HIDE_TOASTER:
      return { ...state,
        toaster: null
      };
    default:
      return state || null;
  }
}
