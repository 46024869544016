import PropTypes from 'prop-types';

export const formikProps = {
  values: PropTypes.object,
  touched: PropTypes.object,
  errors: PropTypes.object,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  handleSubmit: PropTypes.func,
  location: PropTypes.object, // handle to react-router-dom's location
  history: PropTypes.object, // handle to react-router-dom's history
  locationAfterSubmit: PropTypes.string,
  handleModalClick: PropTypes.func
};
