export const settingsNavigation = {
  title: 'IAM Settings',
  subTitle: '',
  defaultSelectedItem: '1',
  leftMenuItems: [
    {
      label: 'Authentication',
      key: 1,
      replace: true,
      route: '/settings/authentication'
    }
  ]

};

