
import * as React from 'react';
import PropTypes from 'prop-types';
import { Enum } from 'enumify';
// import { Link } from 'react-router-dom';

import { Button,
  Input,
  FormItemProvider,
  ContainerLayout,
  FlexLayout,
  Radio,
  RadioGroup,
  Select,
  Modal,
  FormItemInput,
  StackingLayout,
  Title,
  Paragraph
} from 'prism-reactjs';

// getDescendantProp
import { logger, bindFunctions, setDescendantProp, getDescendantProp } from '../../utils';
import { directoryFormAttrNameToModelAttrPath, addlADAttributes, addlLDAPAttributes, commonDirectoryAttributes,
  directoryFormSchema } from '../../metadata'; // getDirectoryModelAttrPath


class DirectoryType extends Enum {}
DirectoryType.initEnum(['ACTIVE_DIRECTORY', 'OPEN_LDAP']);

class SearchType extends Enum {}
SearchType.initEnum(['NON_RECURSIVE', 'RECURSIVE']);

const searchTypesData = [
  {
    label: 'Non Recursive (Default)',
    value: SearchType.NON_RECURSIVE.name
  },
  {
    label: 'Recursive',
    value: SearchType.RECURSIVE.name
  }
];

class AddDirectoryProvider extends React.Component {

  static propTypes = {
    api: PropTypes.object,
    apiFetchRequest: PropTypes.func,
    // clearCacheForEndpoint: PropTypes.func,
    // directoryModel: PropTypes.object,
    // requiredFields: PropTypes.object,
    handleModalClick: PropTypes.func,
    visible: PropTypes.bool,
    error: PropTypes.object,
    idpId: PropTypes.string,
    mode: PropTypes.oneOf(['add', 'update'])
  }

  static defaultProps = {
    requiredFields: {
    }
  }


  constructor(props) {
    super(props);
    bindFunctions(['handleChange', 'handleOnClick'], this);
    this.hydrateState();
  }

  hydrateState(provider) {
    this.state = {};
    if (provider && this.props.mode === 'update') {
      logger('UpdateProvider', JSON.stringify(provider));
      this.state = this.schemaToState(provider);
    } else {
      this.state = this.schemaToState(null);
    }
  }

  componentDidMount() {
    logger('AddProvider', 'check and route to login page or providers page.');
    const { mode, idpId } = this.props;
    if (mode === 'update' && idpId) {
      this.props.apiFetchRequest(
        {
          endpoint: 'GET_DIRECTORY_SERVICE',
          body: {
            dir_id: idpId
          }
        }
      );
    }
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.error && nextProps.api.GET_DIRECTORY_SERVICE) {
      this.hydrateState(nextProps.api.GET_DIRECTORY_SERVICE);
    }
  }
  componentWillUnmount() {
    // this.props.clearCacheForEndpoint({ endpoint: 'ADD_SAML_IDP' });
  }

  render() {
    const { visible, handleModalClick, mode } = this.props;

    return (
      <Modal
        visible={ visible }
        title={ (mode === 'update') ? 'Update Directory' : 'Add Directory' }
        contentClassName="iam-modal-content"
        onCancel={ handleModalClick }
        footer={ this.getFooter() }
        width={ 750 }
      >
        <ContainerLayout padding="15px">
          { this.getBodyContent() }
        </ContainerLayout>
      </Modal>
    );
  }

  getBodyContent() {
    const { directoryModel } = this.state;
    // Upgrade
    const InputWithLabel = FormItemProvider(Input);
    return (
      <FlexLayout itemSpacing="10px" itemFlexBasis="100pc" >
        <StackingLayout >
          <FlexLayout>
            <Title size="h3">Directory Type</Title>
          </FlexLayout>
          <RadioGroup defaultValue={ directoryModel.directory_type }
            name="directory_type_radio"
            id="directory_type" onChange={ this.handleChange }>
            <Radio name="directory_type" title="Active Directory"
              value="ACTIVE_DIRECTORY" />
            <Radio name="directory_type" title="Open LDAP" value="OPEN_LDAP" />
          </RadioGroup>
          <FlexLayout>
            <Title size="h3">Directory Details</Title>
          </FlexLayout>
          <FlexLayout itemFlexBasis="100pc">
            <FormItemInput id="name" name="name"
              label="Name"
              { ...this.isFieldValid('name') }
              onBlur={ this.handleValidate }
              onChange={ this.handleChange }
              value={ directoryModel.name }
              subLabel="(required)" />
            <FormItemInput id="domain_name" name="domain_name"
              label="Domain Name"
              { ...this.isFieldValid('domain_name') }
              onBlur={ this.handleValidate }
              onChange={ this.handleChange } value={ directoryModel.domain_name }
              placeholder="e.g. eng.company.com" />
          </FlexLayout>

          <FlexLayout itemFlexBasis="100pc">
            <FormItemInput id="url" name="url" label="Directory URL"
              { ...this.isFieldValid('url') }
              onBlur={ this.handleValidate }
              onChange={ this.handleChange } value={ directoryModel.url }
              placeholder="e.g. ldap://10.2.3.111:389" />
            <InputWithLabel
              label="Search Type"
              element={
                <Select selectOptions={ searchTypesData }
                  selected={ directoryModel.group_search_type || SearchType.NON_RECURSIVE.name }
                  placeholder="Select..."
                  id="group_search_type"
                  name="group_search_type"
                  onChange={ this.handleChange } />
              }
            />
          </FlexLayout>
          <StackingLayout>
            <Title size="h3">Service Account</Title>
            <Paragraph>{ 'You need to query your company\'s directory service for user details. Ideally use a service account with no time limit.' }</Paragraph>
            <FlexLayout itemFlexBasis="100pc">
              <FlexLayout itemFlexBasis="100pc">
                <FormItemInput id="username" name="username"
                  label="Username"
                  { ...this.isFieldValid('username') }
                  onChange={ this.handleChange }
                  onBlur={ this.handleValidate }
                  value={ directoryModel.username }
                  subLabel="(required)" />
              </FlexLayout>
              <FlexLayout itemFlexBasis="100pc">
                <FormItemInput id="password" name="password"
                  label="Password"
                  type="password"
                  { ...this.isFieldValid('password') }
                  onBlur={ this.handleValidate }
                  onChange={ this.handleChange }
                  value={ directoryModel.password }
                  subLabel="(required)" />
              </FlexLayout>
            </FlexLayout>
          </StackingLayout>
          {
            this.getFormDetails()
          }
        </StackingLayout>
      </FlexLayout>
    );
  }

  getFormDetails() {
    const { directoryModel } = this.state;
    const directory_type = directoryModel.directory_type;
    // const FF = directoryFormAttrNameToModelAttrPath;
    // logger('AddDirectoryProvider.handleChange ', validation);
    if (directory_type === DirectoryType.OPEN_LDAP.name) {
      return (
        <StackingLayout>
          <FlexLayout>
            <Title size="h3">User and Group Details</Title>
          </FlexLayout>
          <FlexLayout itemFlexBasis="100pc">
            <FlexLayout itemFlexBasis="100pc">
              <FormItemInput id="user_object_class"
                name="user_object_class"
                label="User Object Class"
                { ...this.isFieldValid('user_object_class') }
                onBlur={ this.handleValidate }
                onChange={ this.handleChange }
                placeholder="e.g. person or initOrgPerson"
                value={ directoryModel.user_object_class }
                subLabel="(required)" />
            </FlexLayout>
            <FlexLayout itemFlexBasis="100pc">
              <FormItemInput id="user_search_base"
                name="user_search_base"
                label="User Search Base"
                { ...this.isFieldValid('user_search_base') }
                onBlur={ this.handleValidate }
                onChange={ this.handleChange }
                placeholder="e.g. OU=people"
                value={ directoryModel.user_search_base }
                subLabel="(required)" />
            </FlexLayout>
          </FlexLayout>
          <FlexLayout>
            <FormItemInput id="username_attribute"
              name="username_attribute"
              label="Username Attribute"
              onBlur={ this.handleValidate }
              onChange={ this.handleChange }
              { ...this.isFieldValid('username_attribute') }
              placeholder="e.g. uid or userPrincipalName"
              value={ directoryModel.username_attribute }
              subLabel="(required)" />
          </FlexLayout>
          <FlexLayout itemFlexBasis="100pc">
            <FlexLayout itemFlexBasis="100pc">
              <FormItemInput id="group_object_class"
                name="group_object_class"
                label="Group Object Class"
                onBlur={ this.handleValidate }
                onChange={ this.handleChange }
                { ...this.isFieldValid('group_object_class') }
                placeholder="e.g. group"
                value={ directoryModel.group_object_class }
                subLabel="(required)" />
            </FlexLayout>
            <FlexLayout itemFlexBasis="100pc">
              <FormItemInput id="group_search_base"
                name="group_search_base"
                label="Group Search Base"
                onBlur={ this.handleValidate }
                onChange={ this.handleChange }
                { ...this.isFieldValid('group_search_base') }
                placeholder="e.g. OU=people"
                value={ directoryModel.group_search_base }
                subLabel="(required)" />
            </FlexLayout>
          </FlexLayout>
          <FlexLayout itemFlexBasis="100pc">
            <FlexLayout itemFlexBasis="100pc">
              <FormItemInput id="group_member_attribute"
                name="group_member_attribute"
                label="Group Member Attribute Name"
                onBlur={ this.handleValidate }
                onChange={ this.handleChange }
                { ...this.isFieldValid('group_member_attribute') }
                value={ directoryModel.group_member_attribute }
                subLabel="(required)" />
            </FlexLayout>
            <FlexLayout itemFlexBasis="100pc">
              <FormItemInput id="group_member_attribute_value"
                name="group_member_attribute_value"
                label="Group Member Attribute Value"
                onBlur={ this.handleValidate }
                onChange={ this.handleChange }
                { ...this.isFieldValid('group_member_attribute_value') }
                value={ directoryModel.group_member_attribute_value }
                subLabel="(required)" />
            </FlexLayout>
          </FlexLayout>


        </StackingLayout>
      );
    }
  }

  isFieldValid(name) {
    const { validation = [] } = this.state;

    for (const item of validation) {
      if (item.split(' ')[0] === name) {
        return {
          error: true,
          helpText: `${name} is required`
        };
      }
    }
  }

  handleChange = (event) => {
    const value = event.target.value || '';
    const name = event.target.name;
    const { directoryModel } = this.state;
    directoryModel[name] = value;
    this.setState({
      ...Object.assign({}, this.state, { directoryModel })
    });
    // logger('AddDirectoryProvider.handleChange ', directoryModel);
  }

  handleValidate = (event) => {
    const { directoryModel, validation = [] } = this.state;
    let validationNow = validation.slice(0);
    const name = event.target.name;
    if (name) {
      const temp = [];
      validationNow.forEach(item => {
        if (item.split(' ')[0] !== name) {
          temp.push(item);
        }
      });
      validationNow = temp;
      try {
        directoryFormSchema.validateSyncAt(name, directoryModel);
      } catch (error) {
        validationNow.push(error.message);
      }
    }
    this.setState({
      ...Object.assign({}, this.state, { validation: validationNow })
    });

    return validationNow;
  }

  handleOnClick = (evt) => {
    const body = this.getBody();
    if (!body) {
      logger('AddDirectoryProvider.handleOnClick null body!');
      return;
    }
    const { mode, idpId } = this.props;
    let endpoint = 'ADD_DIRECTORY_SERVICE';
    if (mode === 'update' && idpId) {
      endpoint = 'UPDATE_DIRECTORY_SERVICE';
      body.dir_id = idpId;
    }
    const request = {
      passthrough: false,
      endpoint,
      body
    };
    // logger('AddDirectoryProvider.handleOnClick', JSON.stringify(this.state, null, 2));
    this.props.apiFetchRequest([request, { endpoint: 'GET_SAML_IDENTITY_PROVIDERS' }]);
    this.props.handleModalClick('add-provider', request);
  }

  getFooter() {
    const { mode } = this.props;
    const footer = (
      <div>
        <Button key="submit"
          type="primary"
          htmlType="submit"
          onClick={ this.handleOnClick } >
          { (mode === 'update') ? 'Update Directory' : 'Add Directory' }
        </Button>
      </div>
    );

    return footer;
  }

  getBody() {
    const { directoryModel } = this.state;
    let validation;
    try {
      validation = directoryFormSchema.validateSync(directoryModel, { abortEarly: false });
    } catch (err) {
      validation = err.errors;
    }
    if (Array.isArray(validation)) {
      this.setState({
        ...Object.assign({}, this.state, { directoryModel }, { validation })
      });
      return null;
    }
    return this.stateToSchema();
  }

  stateToSchema() {
    const { api, mode } = this.props;
    const { directoryModel } = this.state;
    const FF = directoryFormAttrNameToModelAttrPath;
    const type = directoryModel.directory_type;
    let body = {
      metadata: {}
    };
    if (mode === 'update' && api.GET_DIRECTORY_SERVICE) {
      body = api.GET_DIRECTORY_SERVICE;
    }
    // addlADAttributes, addlLDAPAttributes, commonDirectoryAttributes
    commonDirectoryAttributes.forEach((attr) => {
      setDescendantProp(body, FF[attr], directoryModel[attr]);
    });
    if (type === DirectoryType.ACTIVE_DIRECTORY.name) {
      addlADAttributes.forEach((attr) => setDescendantProp(body, FF[attr], directoryModel[attr]));
    } else if (type === DirectoryType.OPEN_LDAP.name) {
      addlLDAPAttributes.forEach((attr) => setDescendantProp(body, FF[attr], directoryModel[attr]));
    }

    return body;
  }

  schemaToState(provider) {
    let _state;
    if (!provider) {
      _state = {
        directoryModel: {
          name: '',
          directory_type: 'ACTIVE_DIRECTORY',
          domain_name: '',
          group_search_type: '',
          user_object_class: '',
          user_search_base: '',
          username_attribute: '',
          group_member_attribute_value: '',
          group_member_attribute: '',
          group_object_class: '',
          group_search_base: '',
          password: '',
          username: '',
          url: ''
        }
      };
    } else {
      _state = {};
      const FF = directoryFormAttrNameToModelAttrPath;
      const type = getDescendantProp(provider, FF.directory_type);
      const directoryModel = {};
      commonDirectoryAttributes.forEach((attr) => {
        directoryModel[attr] = getDescendantProp(provider, FF[attr]);
      });
      if (type === DirectoryType.ACTIVE_DIRECTORY.name) {
        addlADAttributes.forEach((attr) => {
          directoryModel[attr] = getDescendantProp(provider, FF[attr]);
        });
      } else if (type === DirectoryType.OPEN_LDAP.name) {
        addlLDAPAttributes.forEach((attr) => {
          directoryModel[attr] = getDescendantProp(provider, FF[attr]);
        });
      }
      _state.directoryModel = directoryModel;
    }

    return _state;
  }

}

export default AddDirectoryProvider;
