import * as constants from '../constants';

export const showToaster = (toaster) => ({
  type: constants.SHOW_TOASTER,
  toaster
});

export const hideToaster = () => ({
  type: constants.HIDE_TOASTER
});

export const getSystemConfigAction = (request) => ({
  type: constants.GET_SYSTEM_CONFIG,
  request
});

export const getPrismVersionAction = (request) => ({
  type: constants.GET_PRISM_VERSION,
  request
});

export const getPrismCustomLoginAction = (request) => ({
  type: constants.GET_PRISM_LOGIN_CUSTOM,
  request
});
