import * as constants from '../constants';

/**
 * Document it
 * @param {object} state - state
 * @param {object} action - action
 * @returns {object} - mutated state
 */
export default function api(state, action) {
  switch (action.type) {
    case constants.API_FETCH_SUCCESS:
      const apiHash = {};
      if (action.response.length) {
        action.response.forEach(resp => {
          apiHash[resp.endpoint] = resp.json;
        });
      } else {
        apiHash[action.response.endpoint] = action.response.json;
      }
      return { ...state,
        ...apiHash
      };
    case constants.API_FETCH_FAILED:
      return { ...state,
        [action.response.endpoint]: action.response.json,
        [constants.PENDING]: false
      };
    case constants.API_CLEAR_SUCCESS:
      return { ...state,
        [action.endpoint]: null
      };
    case constants.HIDE_WELCOME_BANNER:
      return { ...state,
        [action.endpointName]: {
          ...state[action.endpointName],
          welcome_banner_status: false
        }
      };
    case constants.HTTP_GET_CALL_COMPLETED:
      return { ...state,
        [constants.PENDING]: false
      };
    case constants.PRISM_API_PRE_LOGIN_SUCCESS:
      // Get customLogin property from /v1/utils/pre_login_details
      return { ...state,
        [constants.CUSTOM_LOGIN]: action.response.json
      };
    case constants.PRISM_API_FETCH_SUCCESS:
      return { ...state,
        [constants.PRISM_VERSION]: action.response.json
      };
    case constants.PRISM_API_FETCH_FAILED:
      return { ...state,
        [constants.PRISM_VERSION]: action.error.json
      };
    default:
      return { ...state,
        [constants.PENDING]: true
      } || null;
  }
}
