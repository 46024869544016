import { WELCOME_BANNER_HAS_TRIGGERED } from '../constants';
/**
 * Set welcome banner status has been trigged in current sessions
 * to session storage
 * @param {Boolean} welcomeBannerExist - has welcome banner exist.
 **/
export const setWelcomeBannerHasTriggered = (welcomeBannerExist) => {
  sessionStorage.setItem(WELCOME_BANNER_HAS_TRIGGERED, welcomeBannerExist);
};

/**
 * Get welcome banner has exist for current session from session storage
 * @returns {Boolean} - if welcome banner exist.
 **/
export const getWelcomeBannerHasTriggered = () => {
  return sessionStorage.getItem(WELCOME_BANNER_HAS_TRIGGERED) === 'true';
};
