import * as React from 'react';
import PropTypes from 'prop-types';
import { Button, StackingLayout, FlexLayout, Input, Alert,
  Table, Modal, CloseIcon, CheckMarkIcon } from 'prism-reactjs';

import { manageserviceAccountKeysColumns, manageserviceAccountKeysPagination,
  manageserviceAccountKeysActions, manageserviceAccountKeysRowAction,
  manageserviceAccountKeysLocalActions } from '../../metadata';
import { logger, bindFunctions } from '../../utils';
import { maxServiceAccountKeysAllowed } from '../../config';
import { Actions } from '../../components/Common';

class ManageServiceAccountKeys extends React.Component {

  static propTypes = {
    actions: PropTypes.object,
    api: PropTypes.object,
    keys: PropTypes.array,
    account: PropTypes.object,
    topSection: PropTypes.object,
    handleModalClick: PropTypes.func
    // maxServiceAccountKeysAllowed: PropTypes.number
  }

  static defaultProps = {
    actions: Object.assign({}, manageserviceAccountKeysActions),
    users: {},
    topSection: {
      title: 'Certificates'
    },
    maxServiceAccountKeysAllowed: maxServiceAccountKeysAllowed || 2
  }

  constructor(props) {
    super(props);
    this.state = {
      pagination: Object.assign({}, manageserviceAccountKeysPagination),
      rowSelection: {
        selected: []
      },
      showGenerateInput: false
    };
    bindFunctions(['handleAction', 'handleCreateCertificate',
      'handleCancelCreateCertificate', 'handleChange', 'handleRowActions'], this);

    this.columns = manageserviceAccountKeysColumns(this.handleRowActions);
    this.rowAction = Object.assign({}, manageserviceAccountKeysRowAction,
      { onRowAction: this.handleRowAction });

    // inut refs, we have a form with two non required fields, circumventing Formik
    this.inputDescription = null;
    this.inputName = null;
  }

  render() {
    const { keys = [], topSection, account, handleModalClick } = this.props;
    const { pagination, showGenerateInput } = this.state;
    // added hack to fix count for now.
    const _paginaion = Object.assign({}, pagination);
    _paginaion.total = keys.length;
    logger('iam:ManageServiceAccountKeys.props.account', JSON.stringify(account));
    const tableData = this.getTableData() || [];
    const footer = (
      <div>
        <Button
          key="submit"
          onClick={ handleModalClick }
          type="primary">Done</Button>
      </div>
    );
    return (
      <Modal
        visible={ true }
        title={ `Manage ${account.name}'s Certificates` }
        contentClassName="iam-modal-content"
        onCancel={ handleModalClick }
        footer={ footer }
        width={ 750 }
      >
        <StackingLayout className="ServiceAccountKeysList" padding="10px">
          { this.getAlert() }
          <FlexLayout className="ServiceAccountKeysList_Actions" justifyContent="flex-end">
            { (showGenerateInput) && (
              <FlexLayout alignItems="center" className="ServiceAccountKeysList_Actions_CreateForm">
                <Input name="nameInput" key="name" label="Name"
                  onChange={ this.handleChange }
                  placeholder="Name of the certificate" />,
                <Input className="key_description" name="descriptionInput" key="description"
                  onChange={ this.handleChange }
                  label="Description" placeholder="Description of the certificate" />
                <Button size="small" appearance="mini" onClick={ this.handleCreateCertificate }>
                  <CheckMarkIcon /></Button>
                <Button type="secondary" appearance="mini" size="small"
                  onClick={ this.handleCancelCreateCertificate }><CloseIcon /></Button>
              </FlexLayout>
            )}
            { (tableData.length > 0) && (
              this.getActions()
            )
            }
          </FlexLayout>
          { (tableData.length === 0) && (
            <div className="NoDataFound">
              <StackingLayout padding="10px">
                <div>No Certificates Found</div>
                { this.getActions('center') }
              </StackingLayout>
            </div>
          )}
          { (tableData.length > 0) && (
            <Table rowKey="uuid" oldTable={ false }
              dataSource={ tableData }
              columns={ this.columns }
              topSection={ topSection }
              structure={ {
                paginationPosition: {
                  top: true,
                  bottom: true
                }
              } } />
          )}
        </StackingLayout>
      </Modal>

    );
  }

  /**
   * We take the nutanix_access_keys and add newly created key if any.
   * @returns {array} - of all the keys.
   */
  getTableData() {
    let nutanixAccessKeys = this.props.account.nutanix_acccess_keys || [];
    nutanixAccessKeys = nutanixAccessKeys.slice(0);
    const { CREATE_SERVICE_ACCOUNTS_KEY } = this.props.api;
    if (CREATE_SERVICE_ACCOUNTS_KEY && !CREATE_SERVICE_ACCOUNTS_KEY.code) {
      nutanixAccessKeys.push(CREATE_SERVICE_ACCOUNTS_KEY);
    }

    return nutanixAccessKeys;
  }

  getAlert() {
    const { CREATE_SERVICE_ACCOUNTS_KEY, DELETE_SERVICE_ACCOUNT_KEY } = this.props.api;
    let alert = null;
    if (CREATE_SERVICE_ACCOUNTS_KEY && CREATE_SERVICE_ACCOUNTS_KEY.code) {
      alert = (<Alert
        type={ Alert.TYPE.ERROR }
        message="Failed to create new certificate. Please try again." />);
    } else if (CREATE_SERVICE_ACCOUNTS_KEY && !CREATE_SERVICE_ACCOUNTS_KEY.code) {
      alert = (<Alert
        type={ Alert.TYPE.SUCCESS }
        message="Successfully created new certificate!" />);
    } else if (DELETE_SERVICE_ACCOUNT_KEY && DELETE_SERVICE_ACCOUNT_KEY.code) {
      alert = (<Alert
        type={ Alert.TYPE.ERROR }
        message="Failed to revoke certificate. Please try again." />);
    } else if (DELETE_SERVICE_ACCOUNT_KEY && !DELETE_SERVICE_ACCOUNT_KEY.code) {
      alert = (<Alert
        type={ Alert.TYPE.SUCCESS }
        message="Successfully revoked certificate!" />);
    }

    return alert;
  }
  getActions(justifyContent) {
    const onHandleAction = this.handleAction;
    return (<Actions cssClass="SamlProvidersList_Actions"
      justifyContent={ justifyContent }
      handleAction={ onHandleAction }
      selectionCount={ this.state.rowSelection.selected.length }
      actions={ this.props.actions } />);
  }

  handleAction(event) {
    const action = event.currentTarget.dataset.action;
    logger('iam:ManageServiceAccountKeys.handleAction', action);
    if (action === 'generate_new') {
      // this.toggleShowGenerateInput();
      this.handleCreateCertificate();
    }
  }

  handleCreateCertificate() {
    // const name = this.inputName.value;
    // const description = this.inputDescription.value;
    const uuid = this.props.account.uuid;
    logger('iam:ManageServiceAccountKeys.createCertificate', 'created success!');
    if (this.props.handleModalClick) {
      this.props.handleModalClick(manageserviceAccountKeysLocalActions.CREATE_KEY, {
        // name,
        // description,
        uuid
      });
    }
  }

  handleCancelCreateCertificate() {
    logger('iam:ManageServiceAccountKeys.createCertificate', 'cancelled create!');
    this.toggleShowGenerateInput();
  }

  toggleShowGenerateInput() {
    this.setState(Object.assign({}, this.state,
      { showGenerateInput: !this.state.showGenerateInput }));
  }

  handleChange = (event) => {
    const value = event.target.value || '';
    const name = event.target.name;
    this.setState({
      ...Object.assign({}, this.state, { [`${name}`]: value })
    });
  }

  handleRowActions(event) {
    const uuid = event.currentTarget.dataset.uuid;
    const action = event.currentTarget.dataset.action;
    const keys = this.getTableData(); // this.props.account.nutanix_acccess_keys;
    const key = keys.find((aKey) => aKey.uuid === uuid);
    if (action === manageserviceAccountKeysLocalActions.DOWNLOAD_CERTIFICATE && key.certificate) {
      event.currentTarget.href =
        `data:text/plain;charset=UTF-8,${encodeURIComponent(key.certificate)}`;
    } else if (action === manageserviceAccountKeysLocalActions.DOWNLOAD_PRIVATE_KEY &&
        key.private_key) {
      event.currentTarget.href =
        `data:text/plain;charset=UTF-8,${encodeURIComponent(key.private_key)}`;
    } else if (action === manageserviceAccountKeysLocalActions.REVOKE_KEY) {
      this.props.handleModalClick(action, { uuid: this.props.account.uuid,
        keyUuid: uuid });
    }

    logger('ManageServiceAccountKeys.handleRowAcrion ',
      action, uuid);
  }

}

export default ManageServiceAccountKeys;
