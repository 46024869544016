import React from 'react';

import { Checkbox } from 'prism-reactjs';
import { getDescendantProp } from '../../utils';

const FRCheckbox = (args) => {
  const {
    values,
    errors,
    setFieldValue,
    setFieldTouched,
    name,
    ...rest
  } = args;

  const value = getDescendantProp(values, name, '.');

  return (<Checkbox label="Reset Password"
    name={ name }
    checked={ value }
    onChange={ event => setFieldValue(name, event.target.checked) }
    onClick={ () => setFieldTouched(name) }
    helpText={ errors[name] }
    { ...rest } />
  );
};


export default FRCheckbox;
