import { get, isEmpty, has } from 'lodash';
import { HOME_URL, AUTH_REQ_EXISTS, IS_USER_LOGGEDIN } from '../constant';

/**
 * Handles force redirect in browser
 * @param {String} url - redirect url
 */
export const forceRedirect = (url = HOME_URL) => {
  window.location.href = url;
};

/**
 * Reture the value of 'auth_req_exists' property from connector
 * if the connector exist and has property 'auth_req_exists'
 * otherwise always return true
 * @param {?Object} connectors - connector object get from API
 * @returns {Boolean} - if the auth_Request cookie exist
 */
export const authRequestExist = (connectors) => {
  // Return true if connectors or AUTH_REQ_EXISTS property doesn't exist
  // Otherwise return value from AUTH_REQ_EXISTS property
  return (isEmpty(connectors) || !has(connectors, AUTH_REQ_EXISTS))
    ? true : get(connectors, AUTH_REQ_EXISTS, false);
};

/**
 * Reture the value of 'is_user_loggedin' property from connector
 * if the connector exist and has property 'is_user_loggedin'
 * otherwise always return false (default value)
 * @param {?Object} connectors - connector object get from API
 * @returns {Boolean} - is_user_loggedin value
 */
export const isUserLoggedin = (connectors) => {
  // Return true if connectors or AUTH_REQ_EXISTS property doesn't exist
  // Otherwise return value from AUTH_REQ_EXISTS property
  return (isEmpty(connectors) || !has(connectors, IS_USER_LOGGEDIN))
    ? false : get(connectors, IS_USER_LOGGEDIN, true);
};
