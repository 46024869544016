/**
  * Extract version number out of a version string
  * @param {String} versionString - long or short version string.
  * @return {String} - short version number in string format. Return the original
          string if it cannot be parsed.
*/
// Treating NOS 'master' version as a large numeric string.
const MASTER_VERSION_NUM = '999999999';

const extractVersionNumber = (versionString) => {
  // We only support trailing wildcard. See AppUtil_spec for details.
  const VERSION_PATTERN = /(\d+(\.\d+\.?)*(\.(\d+|\*)))[^\d\.\*]/g;

  const match = VERSION_PATTERN.exec(versionString);
  if (match) {
    return match[1];
  }
  if (versionString.indexOf('master') >= 0) {
    return MASTER_VERSION_NUM;
  }
  // Fail to parse the input. Return the original string instead of
  // throwing an error since in Dev environment people might use
  // arbitrary version string.
  return versionString;
};

/**
 * Get software version from input string
 * @param {String} str - application version string from API
 * @returns {String} version string
 */
export const getVersionString = (str) => {
  if (str) {
    const versionNum = extractVersionNumber(str);
    return versionNum === MASTER_VERSION_NUM ? 'master' : versionNum;
  }
};
