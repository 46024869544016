import { oidc } from '../config';

const IamApis = {
  DISCOVER: {
    headers: {},
    method: 'get',
    name: 'discover',
    url: oidc.discoveryUrl
  },
  GET_CONNECTORS: {
    headers: {},
    method: 'get',
    name: 'connectors',
    url: '/oidc/connectors'
  },
  GET_USER: {
    headers: {},
    method: 'get',
    name: 'user',
    url: '/users/:uuid'
  },
  CREATE_USER: {
    headers: {},
    method: 'post',
    name: 'create_user',
    url: '/users'
  },
  UPDATE_USER: {
    headers: {},
    method: 'put',
    name: 'update_user',
    url: '/users/:uuid'
  },
  UPDATE_USER_STATUS: {
    headers: {},
    method: 'put',
    name: 'update_user_status',
    url: '/users/:uuid/active'
  },
  GET_USERS: {
    headers: {},
    method: 'get',
    name: 'users',
    url: '/users'
  },
  CHANGE_USER_PASSWORD: {
    method: 'put',
    name: 'change_user_password',
    url: '/users/password'
  },
  SAML_IDENTITY_PROVIDER: {
    headers: {
    },
    method: 'post',
    name: 'saml_identity_provider',
    url: '/saml_identity_provider'
  },
  GET_SAML_IDENTITY_PROVIDERS: {
    headers: {},
    method: 'get',
    name: 'get_saml_identity_providers',
    url: '/saml_identity_providers'
  },
  ADD_SAML_IDP: {
    headers: {
    },
    method: 'post',
    name: 'saml_identity_providers',
    url: '/saml_identity_providers',
    toaster: { success: 'Successfully added the provider.',
      error: 'Failed to add the provider.'
    }
  },
  GET_SAML_IDP: {
    headers: {},
    method: 'get',
    name: 'get_saml_identity_providers',
    url: '/saml_identity_providers/:idp_id'
  },
  DELETE_SAML_IDP: {
    headers: {
    },
    method: 'delete',
    name: 'saml_identity_providers',
    url: '/saml_identity_providers/:idp_id',
    toaster: { success: 'Successfully deleted the provider.',
      error: 'Failed to delete the provider.'
    }
  },
  GET_DIRECTORY_SERVICES: {
    headers: {},
    method: 'get',
    name: 'directory_services',
    url: '/directory_services'
  },
  ADD_DIRECTORY_SERVICE: {
    headers: {},
    method: 'post',
    name: 'directory_services',
    url: '/directory_services',
    toaster: { success: 'Successfully added the directory.',
      error: 'Failed to add the directory.'
    }
  },
  UPDATE_DIRECTORY_SERVICE: {
    headers: {},
    method: 'put',
    name: 'directory_services',
    url: '/directory_services/:dir_id',
    toaster: { success: 'Successfully updated the directory.',
      error: 'Failed to update the directory.'
    }
  },
  GET_DIRECTORY_SERVICE: {
    headers: {
    },
    method: 'get',
    name: 'directory_services',
    url: '/directory_services/:dir_id'
  },
  DELETE_DIRECTORY_SERVICE: {
    headers: {
    },
    method: 'delete',
    name: 'directory_services',
    url: '/directory_services/:dir_id',
    toaster: { success: 'Successfully deleted the directory.',
      error: 'Failed to delete the directory.'
    }

  },

  CREATE_SERVICE_ACCOUNT: {
    headers: {
    },
    method: 'post',
    name: 'add_service_account',
    url: '/service_accounts',
    toaster: { success: 'Successfully created a service account.',
      error: 'Failed to create service account.'
    }
  },
  GET_SERVICE_ACCOUNTS: {
    headers: {},
    method: 'get',
    name: 'service_accounts',
    url: '/service_accounts'
  },
  DELETE_SERVICE_ACCOUNT: {
    headers: {},
    method: 'delete',
    name: 'delete_service_account',
    url: '/service_accounts/:uuid',
    toaster: { success: 'Successfully deleted service account.',
      error: 'Failed to delete service account.'
    }
  },
  CREATE_SERVICE_ACCOUNTS_KEY: {
    headers: {},
    method: 'post',
    name: 'service_accounts',
    url: '/service_accounts/:uuid/nutanix_access_keys',
    toaster: { success: 'Successfully created account key.',
      error: 'Failed to create account key.'
    }
  },
  DELETE_SERVICE_ACCOUNT_KEY: {
    headers: {},
    method: 'delete',
    name: 'service_accounts',
    url: '/service_accounts/:uuid/nutanix_access_keys/:keyUuid'
  },
  GET_SYSTEM_CONFIG: {
    method: 'get',
    name:'iam_system_configuration',
    url: '/system_configuration'
  },
  GET_PRISM_VERSION: {
    method: 'get',
    name:'applianceVersion',
    url: '/PrismGateway/services/rest/v1/utils/pre_login_details'
  }
};

export default IamApis;
