import { call, put } from 'redux-saga/effects';
import api from '../api/fetch';
import IamApis from '../api/iam-apis';
import * as constants from '../constants';

import { apiFetchSuccess, batchHttpCallStarted, batchHttpCallCompleted,
  httpCallCompleted, httpCallStarted, showToaster, hideToaster } from '../actions';
import { logger, pause } from '../utils';

const default_error_400 = 'Bad Request HTTP 400.';
const toasterDisplayTime = 2000;
const badCodes = constants.badHttpCodes;
/**
 * worker Saga: will be fired on LOAD_MEMBERS_REQUESTED actions
 * @param {object} request - request object
 */
export function* fetchAPI(request) {
  const payload = request.request;
  let endpoint;
  if (payload.length) {
    logger('api-saga.fetchAPI', payload.length);
    // const responses = {};
    let batchResponse;
    const batchResponses = [];
    const totalRequests = payload.length;
    yield put(batchHttpCallStarted());
    for (let i = 0; i < totalRequests; i++) {
      yield put(httpCallStarted());
      endpoint = IamApis[payload[i].endpoint];
      batchResponse = yield call(api.invoke, payload[i]);
      batchResponse.endpoint = payload[i].endpoint;
      batchResponse.type = constants.API_FETCH_SUCCESS;
      batchResponses.push(batchResponse);
      if (batchResponse.json && badCodes.indexOf(batchResponse.json.code) > -1) {
        // We have a bad request here
        yield put(showToaster({ type: 'danger',
          text: endpoint.toaster.error || default_error_400 }));
        yield call(pause, { millis: toasterDisplayTime });
        yield put(hideToaster(endpoint.toaster));
        break;
      }
      yield put(httpCallCompleted());
      if (endpoint.toaster) {
        yield put(showToaster(endpoint.toaster));
        yield put(showToaster({ type: 'success',
          text: endpoint.toaster.success }));

        yield call(pause, { millis: toasterDisplayTime });
        yield put(hideToaster(endpoint.toaster));
      }
      endpoint = null;
    }

    yield put(apiFetchSuccess(batchResponses));
    yield put(batchHttpCallCompleted());
  } else {
    endpoint = IamApis[payload.endpoint];
    yield put(httpCallStarted());
    const response = yield call(api.invoke, payload);
    response.endpoint = payload.endpoint;
    response.type = constants.API_FETCH_SUCCESS;

    if (response.json && badCodes.indexOf(response.json.code) > -1) {
      // We have a bad request here
      yield put(showToaster({ type: 'danger',
        text: endpoint.toaster.error || default_error_400 }));
      yield call(pause, { millis: toasterDisplayTime });
      yield put(hideToaster(endpoint.toaster));
    }

    yield put(apiFetchSuccess(response));
    yield put(httpCallCompleted());
    if (endpoint.toaster) {
      yield put(showToaster({ type: 'success',
        text: endpoint.toaster.success }));
      yield call(pause, { millis: toasterDisplayTime });
      yield put(hideToaster(endpoint.toaster));
    }
  }
}

/**
 * @param {object} request - the endpoint to clear
 */
export function* clearAPICache(request) {
  const endpoint = request.endpoint;
  if (endpoint.length) {
    for (let i = 0; i < endpoint.length; i++) {
      yield put({
        type: constants.API_CLEAR_SUCCESS,
        endpoint: endpoint[i].endpoint
      });
    }
  } else {
    yield put({
      type: constants.API_CLEAR_SUCCESS,
      endpoint
    });
  }
}
