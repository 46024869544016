import Polyglot from 'node-polyglot';
import I18n from 'ntnx-i18n';

import { i18nHash } from './en-US/i18n';

const locale = 'en_US';

const polyglot = new Polyglot({
  locale,
  phrases: i18nHash
});

const getI18nService = () => {
  return polyglot;
};

/*
 TO-DO:
  this export const initIntlService is to use ntnx-I18n library.
  All the polyglot config should be removed later on to use only
  the ntnx-I18n library in the project
*/
export const initIntlService = () => I18n.locale(locale, i18nHash);
export default getI18nService;
