
export const getLocalStorage = () => localStorage;
// @todo document it
export const getOidckey = () => {
  const oidcKey = Object.keys(localStorage).filter(item => item.indexOf('oidc') > -1) || [];
  let result = null;
  if (oidcKey.length > 0) {
    result = oidcKey[0];
  }
  return result;
};
