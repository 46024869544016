export const HTTP_GET_CALL_STARTED = 'HTTP_GET_CALL_STARTED';

export const BATCH_HTTP_GET_CALL_STARTED = 'BATCH_HTTP_GET_CALL_STARTED';

export const HTTP_GET_CALL_COMPLETED = 'HTTP_GET_CALL_COMPLETED';

export const BATCH_HTTP_GET_CALL_COMPLETED = 'BATCH_HTTP_GET_CALL_COMPLETED';

export const API_FETCH_REQUEST = 'API_FETCH_REQUEST';

export const API_FETCH_REQUEST_THROTTLED = 'API_FETCH_REQUEST_THROTTLED';

export const API_FETCH_SUCCESS = 'API_FETCH_SUCCESS';

export const API_FETCH_FAILED = 'API_FETCH_FAILED';

export const API_CLEAR = 'API_CLEAR';

export const PRISM_API_FETCH_REQUEST = 'PRISM_API_FETCH_REQUEST';

export const PRISM_API_FETCH_SUCCESS = 'PRISM_API_FETCH_SUCCESS';

export const PRISM_API_PRE_LOGIN_SUCCESS = 'PRISM_API_PRE_LOGIN_SUCCESS';

export const PRISM_API_FETCH_FAILED = 'PRISM_API_FETCH_FAILED';

export const OAUTH_INITIATE = 'OAUTH_INITIATE';

export const OAUTH_TOKEN_SUCCESS = 'OAUTH_TOKEN_SUCCESS';

export const API_CLEAR_SUCCESS = 'API_CLEAR_SUCCESS';

export const SHOW_TOASTER = 'SHOW_TOASTER';

export const HIDE_TOASTER = 'HIDE_TOASTER';

export const HIDE_WELCOME_BANNER = 'HIDE_WELCOME_BANNER';

export const GET_CONNECTORS = 'GET_CONNECTORS';

export const SAVE_GLOBAL_FILTER = 'SAVE_GLOBAL_FILTER';
