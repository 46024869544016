import getI18nService from '../../i18n/I18n';

const i18n = getI18nService();

export const serviceAccountsListColumns = [
  {
    title: i18n.t('ServiceAccountList.columns.name'),
    key: 'name'
  }, {
    title: i18n.t('ServiceAccountList.columns.email'),
    key: 'email_address',
    render: (val) => `${val}`
  }, {
    title: i18n.t('ServiceAccountList.columns.description'),
    key: 'description'
  }, {
    title: i18n.t('ServiceAccountList.columns.createdOn'),
    key: 'created_time',
    render: (celldata, rowData) => new Date(celldata).toLocaleString()
  }
];

export const serviceAccountsListActions = {
  primary: [
    {
      key: 'create',
      value: i18n.t('ServiceAccountList.actions.create')
    }
  ],
  secondary: [
    {
      key: 'manage-certificates',
      value: i18n.t('ServiceAccountList.actions.manageCertificates'),
      selectionCount: 1
    },
    {
      key: 'delete',
      value: i18n.t('ServiceAccountList.actions.delete'),
      selectionCount: -1
    }
  ]
};

export const serviceAccountsListPagination = {
  total: 0,
  pageSize: 5,
  currentPage: 1,
  pageSizeOptions: [5, 10, 15, 25, 50]
};

export const serviceAccountsListRowAction = {
  actions: [
    {
      key: 'deactivate',
      value: i18n.t('ServiceAccountList.actions.deactivate')
    }
  ],
  getPopupContainer: () => document.querySelector('.body-wrapper')
};
