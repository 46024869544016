/**
 * Handles inputs change to set values in state
 * @param {String} paras - props.location.search part
 * @returns {Boolean} if the error message comp should be showed
 */

export const showErrorMsg = (paras) => {
  // Only show error message when paras exist and find find 'ERR' in paras;
  // Don't show err when code num is 412;
  return paras && paras.indexOf('ERR') > -1 && paras.indexOf('412') === -1;
};
