import * as constants from '../constants';

export const apiFetchRequest = (request) => ({
  type: constants.API_FETCH_REQUEST,
  request
});
export const apiFetchRequestThrottled = (request) => ({
  type: constants.API_FETCH_REQUEST_THROTTLED,
  request
});
export const apiFetchSuccess = (response) => ({
  type: constants.API_FETCH_SUCCESS,
  response
});
export const apiFetchFailed = (error) => ({
  type: constants.API_FETCH_FAILED,
  error
});

// Get prism prelogin details api value for prism software_version
export const prismApiFetchCustomLoginSuccess = (response) => ({
  type: constants.PRISM_API_PRE_LOGIN_SUCCESS,
  response
});

export const prismApiFetchSuccess = (response) => ({
  type: constants.PRISM_API_FETCH_SUCCESS,
  response
});

export const prismApiFetchFailed = (error) => ({
  type: constants.PRISM_API_FETCH_FAILED,
  error
});

/**
 * Clear an endpoint cache from store
 * @param {object} endpoint to clear, can be an endpoint OR an
 * array of endpoints if multiple cleanup required.
 * @returns {object} endpoint to be cleared
 */
export const clearCacheForEndpoint = (endpoint) => ({
  type: constants.API_CLEAR,
  endpoint
});
export const clearCacheForEndpointSuccess = (endpointName) => ({
  type: constants.API_CLEAR_SUCCESS
});

export const hideWelcomeBannerAction = (endpointName, bannerStatus) => ({
  type: constants.HIDE_WELCOME_BANNER,
  endpointName,
  bannerStatus
});
