
import * as React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { LeftNavLayout, Link, Tooltip } from 'prism-reactjs';

import { logger } from '../../utils';
import { apiFetchRequest, clearCacheForEndpoint } from '../../actions';
import { LeftNavigation } from '../../components/Common';
import { settingsNavigation } from '../../metadata';

import BeingBuilt from '../BeingBuilt.jsx';
import AddProvider from './AddProvider.jsx';
import SamlProvidersList from './SamlProvidersList.jsx';
import AdProvidersList from './AdProvidersList.jsx';

class SettingsMain extends React.Component {

  static propTypes = {
    api: PropTypes.object,
    apiFetchRequest: PropTypes.func,
    clearCacheForEndpoint: PropTypes.func,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    defaultRoute: PropTypes.array
  }

  static defaultProps = {
    api: {},
    defaultRoute: ['/settings'],
    location: {},
    topSection: {
      title: 'Iam Settings'
    }
  }

  constructor(props) {
    super(props);
    this.state = {};
    this.handleLocationChange = this.handleLocationChange.bind(this);
  }

  componentDidMount() {
    // check and route to login page or providers page.
    logger('SettingsMain', 'check and route to login page or providers page.');
  }

  render() {
    const leftPanel = (<LeftNavigation title={ settingsNavigation.title }
      menuItems={ settingsNavigation.leftMenuItems } />);
    return (
      <LeftNavLayout leftPanel={ leftPanel } rightBodyContent={ this.getRightPanelContent() } />
    );
  }

  getRightPanelContent() {
    const { defaultRoute, location } = this.props;
    const pathname = location.pathname || '';
    if (defaultRoute.includes(pathname) || pathname.indexOf('/authentication') > -1) {
      return [
        <SamlProvidersList
          api={ this.props.api }
          clearCacheForEndpoint={ this.props.clearCacheForEndpoint }
          apiFetchRequest={ this.props.apiFetchRequest } />,
        <AdProvidersList />,
        <Tooltip title="Download Metadata to Configure Identity Provider"><Link download="iam-metadata"
          href={ `${window.location.protocol}//${window.location.host}/api/iam/v1/saml/sp/metadata` } >Download Metadata</Link></Tooltip>];
    } else if (pathname.indexOf('/ldapproviders') > -1) {
      return <BeingBuilt />;
    }

    return <AddProvider />;
  }

  handleLocationChange(url) {
    const { history } = this.props;
    history.push(url);
  }

}


const mapStateToProps = (state) => {
  return {
    api: state.api
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ apiFetchRequest,
    clearCacheForEndpoint }, dispatch);
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SettingsMain));
