import { compose, createStore, applyMiddleware } from 'redux';

import { routerMiddleware } from 'react-router-redux';
// import { routerMiddleware } from 'connected-react-router';

import createRootReducer from '../reducers/index';

// We ned to have mechanism to have dev and prod stores so that we can debig easliy
// process.env.NODE_ENV can be used to bundle.

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const configureStore = (initialState = {}, history, sagaMiddleware) => {
  const middlewares = [sagaMiddleware, routerMiddleware(history)];
  return createStore(
    createRootReducer(history),
    initialState,
    composeEnhancers(applyMiddleware(...middlewares))
  );
};

export default configureStore;
