import * as React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { LeftNavLayout } from 'prism-reactjs';

import { apiFetchRequest, apiFetchRequestThrottled, clearCacheForEndpoint } from '../../actions';
import { LeftNavigation } from '../../components/Common';
import { identitiesNavigation } from '../../metadata';
import UsersList from './UsersList.jsx';
import ServiceAccountsList from './ServiceAccountsList.jsx';
import CreateServiceAccount from './CreateServiceAccount.jsx';
import BeingBuilt from '../BeingBuilt.jsx';
// import { logger } from '../../utils';

import './Identities.less';

class IdentitiesMain extends React.Component {

  static propTypes = {
    apiFetchRequest: PropTypes.func,
    apiFetchRequestThrottled: PropTypes.func,
    clearCacheForEndpoint: PropTypes.func,
    defaultRoute: PropTypes.array,
    history: PropTypes.object,
    location: PropTypes.object,
    api: PropTypes.object
  }

  static defaultProps = {
    defaultRoute: ['/callback', '/identities'],
    api: {},
    location: {},
    topSection: {
      title: 'Users and Groups'
    }
  }

  constructor(props) {
    super(props);
    this.state = {
      users: {
      }
    };
  }

  render() {
    // @TODO count should be total_matches filed https://jira.nutanix.com/browse/ENG-200147
    // added hack users.users.length
    const leftPanel = (<LeftNavigation title={ identitiesNavigation.title }
      menuItems={ identitiesNavigation.leftMenuItems } />
    );

    console.log('============ IDENTITIES');
    return (
      <LeftNavLayout leftPanel={ leftPanel } rightBodyContent={ this.getRightPanelContent() } />
    );
  }

  getRightPanelContent() {
    const { defaultRoute, location, history, api } = this.props;
    const pathname = location.pathname || '';
    if (defaultRoute.includes(pathname) || pathname.indexOf('/users') > -1) {
      return (<UsersList
        api={ api }
        apiFetchRequest={ this.props.apiFetchRequest }
        apiFetchRequestThrottled={ this.props.apiFetchRequestThrottled }
        clearCacheForEndpoint={ this.props.clearCacheForEndpoint }
      />);
    } else if (pathname.indexOf('/serviceusers') > -1) {
      return (<ServiceAccountsList api={ api }
        apiFetchRequest={ this.props.apiFetchRequest }
        clearCacheForEndpoint={ this.props.clearCacheForEndpoint }
      />);
    } else if (pathname.indexOf('/addserviceaccount') > -1) {
      return (<CreateServiceAccount location={ location } history={ history }
        locationAfterSubmit="/identities/serviceusers" />);
    } else if (pathname.indexOf('/managecertificates') > -1) {
      return (<BeingBuilt />);
    }
  }

}

const mapStateToProps = (state) => {
  return {
    api: state.api
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ apiFetchRequest,
    apiFetchRequestThrottled,
    clearCacheForEndpoint }, dispatch);
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(IdentitiesMain));
