import { object, string } from 'yup';

export const directoryFormAttrNameToModelAttrPath = {
  name: 'name',
  directory_type: 'directory_type',
  domain_name: 'domain_name',
  group_search_type: 'group_search_type',
  user_object_class: 'open_ldap_configuration.user_configuration.user_object_class',
  user_search_base: 'open_ldap_configuration.user_configuration.user_search_base',
  username_attribute: 'open_ldap_configuration.user_configuration.username_attribute',
  group_member_attribute_value: 'open_ldap_configuration.user_group_configuration.group_member_attribute_value',
  group_member_attribute: 'open_ldap_configuration.user_group_configuration.group_member_attribute',
  group_object_class: 'open_ldap_configuration.user_group_configuration.group_object_class',
  group_search_base: 'open_ldap_configuration.user_group_configuration.group_search_base',
  password: 'service_account.password',
  username: 'service_account.username',
  url: 'url'
};

export const commonDirectoryAttributes = ['name', 'directory_type', 'domain_name', 'url', 'group_search_type', 'password', 'username'];
export const addlADAttributes = [];
export const addlLDAPAttributes = ['user_object_class', 'user_search_base', 'username_attribute',
  'group_object_class', 'group_search_base', 'group_member_attribute', 'group_member_attribute_value'];
export const getDirectoryModelAttrPath = (name) => directoryFormAttrNameToModelAttrPath[name];

export const commonDirectoryAttributesRequired = ['name', 'directory_type', 'domain_name', 'url', 'password', 'username'];
export const addlLDAPAttributesRequired = addlLDAPAttributes;

const schema = {};

commonDirectoryAttributesRequired.forEach(item => {
  schema[item] = string().required();
});

addlLDAPAttributesRequired.forEach(item => {
  schema[item] = string().required();
});

export const directoryFormSchema1 = object().shape(schema);
export const directoryFormSchema = object().shape({
  name: string().required(),
  directory_type: string().required(),
  domain_name: string().required(),
  username: string().required(),
  password: string().required(),
  url: string().required(),
  user_search_base: string().when('directory_type', {
    is: 'OPEN_LDAP',
    then: string().required()
  }),
  user_object_class: string().when('directory_type', {
    is: 'OPEN_LDAP',
    then: string().required()
  }),
  username_attribute: string().when('directory_type', {
    is: 'OPEN_LDAP',
    then: string().required()
  }),
  group_member_attribute_value: string().when('directory_type', {
    is: 'OPEN_LDAP',
    then: string().required()
  }),
  group_member_attribute: string().when('directory_type', {
    is: 'OPEN_LDAP',
    then: string().required()
  }),
  group_object_class: string().when('directory_type', {
    is: 'OPEN_LDAP',
    then: string().required()
  }),
  group_search_base: string().when('directory_type', {
    is: 'OPEN_LDAP',
    then: string().required()
  })
});
