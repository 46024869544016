// Error Code from https://github.com/nutanix-core/iam-user-authn/blob/master/connector/statusCodes.go
import getI18nService from '../i18n/I18n';

const i18n = getI18nService();
const getFullErrorMsg = (errorCodeMsg) => {
  return i18n.t('ErrorMessages.errorMessage', {
    error_code: errorCodeMsg
  });
};

// Replace all nonumerical digit in string, e.g. '19' from 'ec=ERR-19'
const getNumFromStr = (str) => str.replace(/\D/g, '');
/**
 * Handles inputs change to set values in state
 * @param {String} errorParaStr - error message code string
 * @returns {String} error message content
 */
export const getErrorMsg = (errorParaStr) => {
  if (!errorParaStr || errorParaStr === '') {
    return '';
  }
  // Get errorCode '19' from parameter like 'ec=ERR-19'
  const errorCode = getNumFromStr(errorParaStr);
  const unknowErrorMsg = i18n.t('ErrorMessages.unknownErrorMessage');

  // If get valid errorCode
  if (errorCode && Number(errorCode) >= 0) {
    const errorCodeMsg = i18n.t(`ErrorMessages.error${errorCode}`);
    return errorCodeMsg.includes('ErrorMessages')
      ? unknowErrorMsg : getFullErrorMsg(errorCodeMsg);
  }
  return unknowErrorMsg;
};
