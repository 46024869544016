import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Route, BrowserRouter as Router } from 'react-router-dom';

import HomePage from '../pages/HomePage';
import NotFoundPage from '../pages/NotFoundPage.jsx';
import ForceLogin from '../components/Demo/ForceLogin.jsx';
import IdentitiesMain from '../pages/Identities/IdentitiesMain.jsx';
import SettingsMain from '../pages/Settings/SettingsMain.jsx';
import LoginPage from '../pages/LoginPage/index.js';
import BeingBuilt from '../pages/BeingBuilt.jsx';
import Loading from '../pages/Loading.jsx';
import Providers from '../pages/LoginPage/Providers.jsx';
import ChangePassword from '../pages/ChangePassword';
import { PATH_URL } from '../constant';
import '../index.css';

const routes = [
  {
    path: '/',
    exact: true,
    render: (props) => (
      <HomePage { ...props }>
        <Loading />
      </HomePage>
    )
  },
  {
    path: '/callback',
    exact: false,
    render: (props) => (
      <HomePage { ...props }>
        <Loading />
      </HomePage>
    )
  },
  {
    path: '/settings',
    exact: true,
    render: (props) => (
      <HomePage { ...props }>
        <SettingsMain />
      </HomePage>
    )
  },
  {
    path: '/settings/authentication',
    exact: true,
    render: (props) => (
      <HomePage { ...props }>
        <SettingsMain />
      </HomePage>
    )
  },
  {
    path: '/settings/addsamlprovider',
    exact: false,
    render: (props) => (
      <HomePage { ...props }>
        <SettingsMain />
      </HomePage>
    )
  },
  {
    path: '/identities',
    exact: true,
    render: (props) => (
      <HomePage { ...props }>
        <IdentitiesMain />
      </HomePage>
    )
  },
  {
    path: '/identities/users',
    exact: true,
    render: (props) => (
      <HomePage { ...props }>
        <IdentitiesMain />
      </HomePage>
    )
  },
  {
    path: '/identities/groups',
    exact: true,
    render: (props) => (
      <HomePage { ...props }>
        <IdentitiesMain />
      </HomePage>
    )
  },
  {
    path: '/identities/serviceusers',
    exact: true,
    render: (props) => (
      <HomePage { ...props }>
        <IdentitiesMain />
      </HomePage>
    )
  },
  {
    path: '/identities/addserviceaccount',
    exact: true,
    render: (props) => (
      <HomePage { ...props }>
        <IdentitiesMain />
      </HomePage>
    )
  },
  {
    path: '/identities/managecertificates',
    exact: true,
    render: (props) => (
      <HomePage { ...props }>
        <IdentitiesMain />
      </HomePage>
    )
  },
  {
    path: '/login',
    exact: true,
    render: (props) => <LoginPage { ...props } />
  },
  {
    path: '/forcelogin',
    exact: true,
    render: (props) => <ForceLogin />
  },
  {
    path: '/providers',
    exact: true,
    render: (props) => <Providers { ...props } />
  },
  {
    path: '/roles',
    exact: true,
    render: (props) => (
      <HomePage { ...props }>
        <BeingBuilt />
      </HomePage>
    )
  },
  {
    path: '/pagenotfound',
    exact: true,
    render: (props) => (
      <HomePage { ...props }>
        <NotFoundPage />
      </HomePage>
    )
  },
  {
    path: '/change-password',
    exact: true,
    render: () => <ChangePassword />
  }
];

/**
 * Class components Routes
 */
class Routes extends Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
  }
  render() {
    const { history } = this.props;
    return (
      <Router history={ history } basename={ PATH_URL.BASENAME }>
        <div className="Iam">
          <div className="iam-main">
            {routes.map((route, index) => (
              <Route
                key={ index }
                path={ route.path }
                exact={ route.exact }
                render={ route.render }
              />
            ))}
          </div>
        </div>
      </Router>
    );
  }
}

Routes.propTypes = {
  history: PropTypes.object
};

export default Routes;
