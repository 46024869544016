import set from 'lodash/set';

/**
 * @param {array} functions that we ned ot bind
 * @param {any} _this context
 * @example
 * bindFunctions(['foo', 'bar'], this);
 */
export function bindFunctions(functions, _this) {
  functions.forEach(
    f => {
      _this[f] = _this[f].bind(_this);
    }
  );
}


/**
 * @param {object} obj Object to scan
 * @param {string} path json path
 * @param {string} splitter to use defaluts to '.'
 * @returns {any} result
 */
export const getDescendantProp = (obj, path, splitter = '.') => {
  return path.split(splitter).reduce((acc, part) => acc && acc[part], obj);
};

/**
 * @param {object} obj Object to scan
 * @param {string} path json path
 * @param {any} value to set for the key.
 * @returns {any} result
 * @TODO will fix later right now using lodash
 */
export const setDescendantProp = (obj, path, value) => {
  return set(obj, path, value);
};


export const msDownloadTextAsFile = (event, text, filename) => {
  if (navigator.appVersion.toString().indexOf('.NET') > 0) {
    event.preventDefault();
    const textFileAsBlob = new Blob([text], {
      type: 'text/plain'
    });
    window.navigator.msSaveBlob(textFileAsBlob, filename);
  }
};

export const copyToClipboard = (input) => {
  if (input && input.select) {
    input.select();
    try {
      // copy text
      document.execCommand('copy');
      input.blur();
    } catch (err) {
      // do nothing.
    }
  }
};

export const pause = (delay) => {
  return new Promise(resolve => {
    setTimeout(_ => {
      resolve();
    }, delay.millis);
  });
};

export const getModelByKey = (key = 'uuid', value, list = []) => {
  for (const item of list) {
    if (item[key] === value) {
      return item;
    }
  }
};

/**
  * Returns a function, that, as long as it continues to be invoked, will not
  * be triggered. The function will be called after it stops being called for
  * N milliseconds. If `immediate` is passed, trigger the function on the
  * leading edge, instead of the trailing.
  * @param function function Function to invoke
  * @param wait time in ms to wait.
  * @param immediate boolean trigger the function on the leading edge, instead of the trailing.
  * @returns function
  */

export const debounce = (func, wait, immediate) => {
  let timeout;
  const debounced = (...args) => {
    const context = this;
    const later = () => {
      timeout = null;
      if (!immediate) {
        func.apply(context, args);
      }
    };
    const callNow = (immediate && !timeout);
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) {
      func.apply(context, args);
    }
  };

  debounced.cancel = () => {
    clearTimeout(timeout);
    timeout = null;
  };

  return debounced;
};
