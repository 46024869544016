import { apiRoot } from '../config';

/** call to system_configuration endpoint
 * @param {Object} request - Request object with url and method.
 * @returns {Object} response data
*/
export const fetchSystemConfig = async (request) => {
  const response = await fetch(`${apiRoot}${request.url}`, { method: request.method });
  const data = await response.json();
  return data;
};

/** call to prism_version endpoint
 * @param {Object} request - Request object with url and method.
 * @returns {Object} response data
*/
export const fetchPrismVersion = async (request) => {
  const response = await fetch(`${request.url}`, { method: request.method });
  const data = await response.json();
  // return the whole pre_login_details object
  return data;
};
