// Render Prop
import React from 'react';

import { withFormik } from 'formik';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Button, StackingLayout, Modal } from 'prism-reactjs';

import { apiFetchRequest, clearCacheForEndpoint } from '../../actions';
import { formikProps } from '../../utils';
import { FRInputPlusLabel } from '../../components/Common';

class CreateServiceAccountForm extends React.Component {

  static propTypes = formikProps;

  render() {
    const { values, errors, setFieldValue, setFieldTouched, handleSubmit,
      handleModalClick } = this.props;
    const fp = {
      values,
      errors,
      setFieldValue,
      setFieldTouched
    };
    return (
      <Modal
        visible={ true }
        title="Create Service Account"
        contentClassName="iam-modal-content"
        footer={ this.getFooter() }
        onCancel={ handleModalClick }
        width={ 500 }
      >
        <div className="CreateServiceAccount">
          <form onSubmit={ handleSubmit }>
            <StackingLayout>
              <FRInputPlusLabel label="Name"
                placeholder="Unique name for the service account"
                name="name"
                { ...fp } />
              <FRInputPlusLabel label="Email"
                placeholder="An email address for the service account"
                name="email_address"
                { ...fp } />
              <FRInputPlusLabel error={ errors.email_address } label="Description"
                placeholder="Description for the service account"
                name="description"
                { ...fp } />
            </StackingLayout>
          </form>
        </div>
      </Modal>
    );
  }

  getFooter() {
    const { handleSubmit } = this.props;
    const footer = (
      <div>
        <Button key="submit"
          type="primary"
          htmlType="submit"
          onClick={ handleSubmit } >
          Submit
        </Button>
      </div>
    );

    return footer;
  }

}

const formikEnhancer = withFormik({
  mapPropsToValues: () => ({
    name: '',
    description: '',
    email_address: ''
  }),
  initialValues:{ email: '',
    password: '' },
  handleSubmit: (values, formikBag) => {
    const { setSubmitting } = formikBag;
    const request = [{
      endpoint: 'CREATE_SERVICE_ACCOUNT',
      body: values
    }, { endpoint: 'GET_SERVICE_ACCOUNTS' }];
    formikBag.props.apiFetchRequest(request);
    setSubmitting(false);
    // formikBag.props.history.push(formikBag.props.locationAfterSubmit);
    formikBag.props.handleModalClick('close');
  },
  displayName: 'BasicForm',
  validate: (values) => {
    const errors = {};
    if (!values.name) {
      errors.name = 'Required';
    }

    return errors;
  }
})(CreateServiceAccountForm);

const CreateServiceAccount = connect(
  state => state.api,
  dispatch => bindActionCreators({ apiFetchRequest,
    clearCacheForEndpoint }, dispatch)
)(formikEnhancer);

export default CreateServiceAccount;
