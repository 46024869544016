import * as constants from '../constants';

export const httpCallStarted = (request) => ({
  type: constants.HTTP_GET_CALL_STARTED,
  request
});
export const batchHttpCallStarted = (request) => ({
  type: constants.BATCH_HTTP_GET_CALL_STARTED,
  request
});
export const httpCallCompleted = (request) => ({
  type: constants.HTTP_GET_CALL_COMPLETED,
  request
});
export const batchHttpCallCompleted = (request) => ({
  type: constants.BATCH_HTTP_GET_CALL_COMPLETED,
  request
});
