import { routerReducer } from 'react-router-redux';
import { combineReducers } from 'redux';
// import { connectRouter } from 'connected-react-router';

import api from './api';
import oauth from './oauth';
import application from './application';

export default (history) => combineReducers({
  api,
  application,
  oauth,
  router: routerReducer
});
