const SUB = '/ui/iam';
const PREFIX = '';

const PATH_URL = {
  BASENAME: '/ui/iam',
  DEFAULT_SUB_PATH: '/ui/iam',
  DEFAULT: PREFIX + '/',
  LOGIN: PREFIX + '/login',
  FORGETLOGIN: PREFIX + '/forcelogin',
  PROVIDERS: PREFIX + '/providers',
  ROLES: PREFIX + '/roles',
  PAGENOTFOUND: PREFIX + '/pagenotfound',
  CHANGEPASSWORD: PREFIX + '/change-password',
  IDENTITIES: PREFIX + '/identities',
  SETTINGS: PREFIX + '/settings'
};
const HANDLE_ERROR = 'HANDLE_ERROR';
const ERROR_EXIST = 'ERROR_EXIST';

// util forceRedirect
const HOME_URL = '/';
const AUTH_REQ_EXISTS = 'auth_req_exists';
const IS_USER_LOGGEDIN = 'is_user_loggedin';

export {
  SUB,
  PATH_URL,
  PREFIX,
  HANDLE_ERROR,
  ERROR_EXIST,
  HOME_URL,
  AUTH_REQ_EXISTS,
  IS_USER_LOGGEDIN
};
